import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { styled, useTheme } from '@mui/material/styles';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import logo from './images/logo.png';
import { AnalyticsChart, CountryChart, DeviceChart, EventChart, UserActivityChart, NewUsersChart } from './AnalyticsChart';
import Box from '@mui/material/Box';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Modal,
  FormControl,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BuildIcon from '@mui/icons-material/Build';
import StarIcon from '@mui/icons-material/Star';
import ArticleIcon from '@mui/icons-material/Article';
import ContactsIcon from '@mui/icons-material/Contacts';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { Line } from "react-chartjs-2";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);




export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState('Dashboard'); // State to manage the displayed content
  const [enquirydata, setEnquirydata] = React.useState([]);
  const [filteredEnquiryData, setFilteredEnquiryData] = React.useState([]);
  const [filteredContactData, setFilteredContactData] = React.useState([]);
  const [contactdata, setContactdata] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [sanckbaropen, setSnackbaropen] = React.useState(false);
  const [snackbarmessage, setSnackbarmessage] = React.useState('');
  const [nameFilter, setNameFilter] = React.useState('');
  const [emailFilter, setEmailFilter] = React.useState('');
  const [dateFilter, setDateFilter] = React.useState('');
  const[formnamefilter,setFormNameFilter]=React.useState('');
  const [formemailfilter,setFormEmailFilter]=React.useState('');
  const[formdatefilter,setFormDateFilter]=React.useState('');
  const [contactnameFilter, setContactNameFilter] = React.useState('');
  const [contactemailFilter, setContactEmailFilter] = React.useState('');
  const [contactdateFilter, setContactDateFilter] = React.useState('');
  const [activeUsers, setActiveUsers] = React.useState(0);
  const [websiteClicks, setWebsiteClicks] = React.useState(0);
  const [monthlyTraffic, setMonthlyTraffic] = React.useState(0);
  const [conversionRates, setConversionRates] = React.useState(0);
  const [avgSessionDuration, setAvgSessionDuration] = React.useState(0);
  const [analyticsData, setAnalyticsData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [countryData, setCountryData] = React.useState(null);
  const [deviceData, setDeviceData] = React.useState(null);
  const [eventData, setEventData] = React.useState([]);
  const [useractiviy, setUserActivity] = React.useState(null);
  const [newuserdata, setNewUserData] = React.useState(null);
  const [modalloading, setModalloading] = React.useState(true);
  const [modalopen, setModalopen] = React.useState(true);
  const [formData, setFormData] = React.useState({
    Category: '',
    Tag: '',
    Author: '',
    Image: null,
    Title: '',
    Description: '',
  });
  const [blogdata, setBlogdata] = React.useState([]);
  const [blogmodal, setBlogModal] = React.useState(false);
  const [blogmodaldata, setBlodModaldata] = React.useState({});
  const [blogopen, setBlogOpen] = React.useState(false);
  const [title, setTitle] = useState('');
  const [paragraphs, setParagraphs] = useState([
    {
      text: '',
      backlinks: [
        {
          text: '',
          url: '',
        },
      ],
    },
  ]);
  const [image, setImage] = useState(null);
  const [blogPost, setBlogPost] = useState([]);
  const [submitformdata, setsubmitformdata] = useState([]);
  const[filteredformdata,setFilteredFormData]=useState([]);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: "500px",
    overflowY: 'auto',
  };

  // submitformdata
  React.useEffect(() => {
    fetch('https://selectcards.ai/getsubmitform.php')
      .then(response => response.json())
      .then(values => setsubmitformdata(values));

  }, [])

  // submitformdata

  // deleteformdata
  const handleformDelete = async (id) => {
    try {
      const response = await fetch('https://selectcards.ai/deleteformdata.php/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: id }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        // Update the UI by removing the deleted record
        setsubmitformdata((prevData) => prevData.filter((item) => item.id !== id));
        alert('Record deleted successfully');
      } else {
        console.error('Error deleting record:', data.message);
      }
    } catch (error) {
      console.error('Network or server error:', error);
    }
  };

  // deleteformdata

  const modalClose = () => {
    setModalopen(false);
  }

  const seperateblogmodal = async (id) => {
    try {
      const response = await fetch(`https://selectcards.ai/getblogbyid.php?id=${id}`);
      const data = await response.json();
      console.log(data);
      if (data && data.paragraphs) {
        setBlodModaldata(data);
        setBlogModal(true);
      } else {
        console.log("Invalid data format");
      }

    } catch (error) {
      console.log(error);
      alert("some error occured");
    }

  }

  const updateblogs = async (id) => {
    const blogData = blogmodaldata;
  
    const updatedBlogData = {
      id: id,
      title: blogData.title,
      paragraphs: blogData.paragraphs, // Directly use blogData paragraphs
      image: blogData.image,
      created_at: blogData.created_at,
    };
  
    try {
      const response = await fetch('https://selectcards.ai/blogupdate.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedBlogData),
      });
  
      if (response.ok) {
        alert("Blog updated successfully");
      } else {
        const errorData = await response.json();
        alert(`Error updating blog: ${errorData.message}`);
      }
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    }
  };
  

  useEffect(() => {
    // Fetch blog post data from the backend
    const fetchBlogPost = async () => {
      try {
        const response = await fetch('https://selectcards.ai/getblog.php'); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch blog post');
        }
        const data = await response.json();
        setBlogPost(data);
        console.log(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalloading(false);
      setModalopen(false);
    }, 4000); // Set the delay time in milliseconds (e.g., 1000ms = 1 second)

    return () => clearTimeout(timer);
  })


  useEffect(() => {
    const fetchNewUserActivityData = async () => {
      try {
        const response = await fetch('https://selectcards.ai/proxy.php?api=newusers');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setNewUserData(data);
      } catch (error) {
        console.error('Error fetching country data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchNewUserActivityData();
  }, [])


  useEffect(() => {
    const fetchUserActivityData = async () => {
      try {
        const response = await fetch('https://selectcards.ai/proxy.php?api=useractivity');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUserActivity(data);
      } catch (error) {
        console.error('Error fetching country data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserActivityData();
  }, [])

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch('https://selectcards.ai/proxy.php?api=events');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setEventData(data);
      } catch (error) {
        console.error('Error fetching country data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchEventData();
  }, [])

  useEffect(() => {
    const fetchDeviceData = async () => {
      try {
        const response = await fetch('https://selectcards.ai/proxy.php?api=device');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setDeviceData(data);
      } catch (error) {
        console.error('Error fetching country data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchDeviceData();
  }, [])


  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await fetch('https://selectcards.ai/proxy.php?api=country');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCountryData(data);
      } catch (error) {
        console.error('Error fetching country data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchCountryData();
  }, [])



  React.useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const response = await fetch('https://selectcards.ai/proxy.php?api=analytics');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAnalyticsData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalyticsData();
  }, []);


  // Dashboard Api
  React.useEffect(() => {
    // Fetch data from your API or backend
    fetch("https://selectcards.ai/proxy.php?api=analyticsdata")
      .then((res) => res.json())
      .then((data) => {
        setActiveUsers(data.activeUsers);
        setWebsiteClicks(data.websiteClicks);
        setMonthlyTraffic(data.monthlyTraffic);
        setConversionRates(data.conversionRates);
        setAvgSessionDuration(data.avgSessionDuration);
      })
      .catch((error) => console.error("Error fetching analytics data:", error));
  }, []);

  const trafficChartData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"], // Example labels
    datasets: [
      {
        label: "Monthly Traffic",
        data: [1000, 1500, 1200, 1800], // Example data
        fill: false,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  // Dashboard Api

  // snackbar
  const handleClose = () => {

    setSnackbaropen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // snackbar

  // CSV Report
  const handleGenerateCSV = () => {
    if (filteredContactData.length === 0) {
      setSnackbarmessage("No data available to export.");
      setSnackbaropen(true);
      return;
    }

    // Prepare CSV data
    const headers = [
      "ID",
      "Name",
      "Email Address",
      "Mobile No",
      "Monthly Income",
      "Employment Status",
      "No. of Existing Cards/Loans",
      "Highest Limit of Existing Card",
      "City",
      "Date & Time",
    ];

    const rows = filteredContactData.map(row => [
      row.ID,
      row.customername,
      row.email,
      row.mobileno,
      row.monthlyincome,
      row.employmentstatus,
      row.existingcards,
      row.highestlimit,
      row.city,
      row.DateTime,
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map(row => row.map(value => `"${value}"`).join(",")),
    ].join("\n");

    // Create a Blob and download the file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ContactData.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  // CSV Report

  // Enquiry CSV Report
  const handleGenerateEnquiryCSV = () => {
    if (filteredEnquiryData.length === 0) {
      setSnackbarmessage("No data available to export.");
      setSnackbaropen(true);
      return;
    }

    // Prepare data for Excel
    const rows = filteredEnquiryData.map(row => ({
      ID: row.id, // Ensure this property exists in your data
      Name: row.customername, // Ensure this property exists in your data
      EmailAddress: row.email, // Ensure this property exists in your data
      MobileNo: row.mobileno, // Ensure this property exists in your data
      DateTime: row.datetime, // Ensure this property exists in your data
    }));

    // Create a new workbook and add the data
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Enquiry Data');

    // Export the Excel file
    XLSX.writeFile(workbook, 'EnquiryData.xlsx');
  }
  // Enquiry CSV Report

  // formdata CSV Report

  const handleGenerateFormCSV = () => {
    if (filteredformdata.length === 0) {
      setSnackbarmessage("No data available to export.");
      setSnackbaropen(true);
      return;
    }

    // Prepare data for Excel
    const rows = filteredformdata.map(row => ({
      ID: row.id, // Extracting the ID from the JSON object
    Name: row.customername, // Extracting the customer name
    EmailAddress: row.email, // Extracting the email
    MobileNo: row.mobileno, // Extracting the mobile number
    Pincode: row.pincode, // Extracting the pincode
    MonthlyIncome: row.monthlyincome, // Extracting monthly income
    EmploymentStatus: row.employmentstatus, // Extracting employment status
    ExistingCards: row.existingcards, // Extracting existing cards
    HighestLimit: row.highestlimit, // Extracting highest limit
    LookingFor: row.lookingfor, // Extracting looking-for field
    ExistingLoans: row.existingLoans, // Extracting existing loans
    OnlineSpendings: row.onlinespendings, // Extracting online spendings
    OfflineSpendings: row.offlinespendings, // Extracting offline spendings
    Lifestyle: row.lifestyle // Extracting lifestyle
    }));

    // Create a new workbook and add the data
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Enquiry Data');

    // Export the Excel file
    XLSX.writeFile(workbook, 'EnquiryData.xlsx');
  }
  // formdata CSV Report

  // loader

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Disable loader after 3 seconds
    }, 6000);

    return () => clearTimeout(timer);
  }, [])
  // loader



  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://selectcards.ai/dataget.php'); // Replace with your API URL
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setEnquirydata(data.users); // Update state with fetched data
        setFilteredEnquiryData(data.users);
        console.log(data);
      } catch (err) {
        console.log(err.message); // Set error if any
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://selectcards.ai/getcontact.php'); // Replace with your API URL
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setContactdata(data.data); // Update state with fetched data
        setFilteredContactData(data.data);
        console.log(data.data.length);
      } catch (err) {
        console.log(err.message); // Set error if any
      }
    };

    fetchData();
  }, []);

  // Enquiry Search

  const handleSearch = () => {
    const filtered = enquirydata.filter((row) => {
      const matchesName = nameFilter
        ? row.customername.toLowerCase().includes(nameFilter.toLowerCase())
        : true;
      const matchesEmail = emailFilter
        ? row.email.toLowerCase().includes(emailFilter.toLowerCase())
        : true;
      const matchesDate = dateFilter
        ? row.datetime.startsWith(dateFilter) // Match date filter
        : true;

      return matchesName && matchesEmail && matchesDate;
    });

    setFilteredEnquiryData(filtered); // Update the filtered data
  };

  const resetFilters = () => {
    setNameFilter('');
    setEmailFilter('');
    setDateFilter('');
    setFilteredEnquiryData(enquirydata); // Reset to full data
  };

  // Enquiry Search

  // Contact Search

  const ContacthandleSearch = () => {
    const filteredData = contactdata.filter((row) => {
      const nameMatches = contactnameFilter
        ? row.customername.toLowerCase().includes(contactnameFilter.toLowerCase())
        : true;
      const emailMatches = contactemailFilter
        ? row.email.toLowerCase().includes(contactemailFilter.toLowerCase())
        : true;
      const dateMatches = contactdateFilter
        ? row.DateTime.startsWith(contactdateFilter) // Match date filter
        : true;

      return nameMatches && emailMatches && dateMatches;
    });
    setFilteredContactData(filteredData);
  };

  const handleReset = () => {
    setContactNameFilter('');
    setContactEmailFilter('');
    setContactDateFilter('');
    setFilteredContactData(contactdata);
  };

  // Contact Search

  // Form Search
  const FormhandleSearch = () => {
    const filteredData = submitformdata.filter((row) => {
      const nameMatches = formnamefilter
        ? row.customername.toLowerCase().includes(formnamefilter.toLowerCase())
        : true;
      const emailMatches = formemailfilter
        ? row.email.toLowerCase().includes(formemailfilter.toLowerCase())
        : true;
        const dateMatches = formdatefilter
        ? row.DateTime.startsWith(formdatefilter) // Match date filter
        : true;

      return nameMatches && emailMatches && dateMatches;
    });
    setFilteredFormData(filteredData);
  };

  const handleformReset = () => {
    setFormNameFilter('');
    setFormEmailFilter('');
    setFormDateFilter('');
    setFilteredFormData(submitformdata);
  };
  // Form Search
  // Delete the Enquiry
  const handleDelete = async (userId) => {
    if (!userId) {
      alert("Please enter a user ID.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch("https://selectcards.ai/deleteenquiry.php", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ id: userId }),
      });

      const data = await response.json();

      if (data.status === "success") {
        setSnackbarmessage(`User with ID ${userId} deleted successfully!`);
        setSnackbaropen(true);
        setFilteredEnquiryData((prevData) => prevData.filter((row) => row.id !== userId));
      } else {
        setSnackbarmessage(data.message);
        setSnackbaropen(true);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user.");
    } finally {
      setLoading(false); // Stop loading after the operation
    }
  };

  // Delete the Enquiry


  // Delete the Contact
  const deleteRecord = async (id) => {
    try {
      const response = await fetch('https://selectcards.ai/deletecontact.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ ID: id }),
      });

      const result = await response.json();

      if (result.status === 'success') {
        setSnackbarmessage(result.message); // Notify the user
        // Refresh the data or update the state to remove the deleted record
        setSnackbaropen(true);
        setFilteredContactData((prevData) => prevData.filter((row) => row.id !== id));
      } else {
        setSnackbarmessage(result.message); // Handle error
        setSnackbaropen(true);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
      setSnackbarmessage('Something went wrong. Please try again.');
      setSnackbaropen(true);
    }
  };
  // Delete the Contact

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // DatewiseData
  const FetchDatewisedata = async () => {
    try {
      const startDate = document.getElementById('startDate').value;
      const endDate = document.getElementById('endDate').value;
      setModalloading(true);
      setModalopen(true);


      const response = await fetch(`https://selectcards.ai/proxy.php?api=analytics&startDate=${startDate}&endDate=${endDate}`);
      const data = await response.json();

      const countryresponse = await fetch(`https://selectcards.ai/proxy.php?api=country&startDate=${startDate}&endDate=${endDate}`);
      const countrydata = await countryresponse.json();
      console.log(countrydata)

      const deviceresponse = await fetch(`https://selectcards.ai/proxy.php?api=device&startDate=${startDate}&endDate=${endDate}`);
      const devicedata = await deviceresponse.json();

      const eventresponse = await fetch(`https://selectcards.ai/proxy.php?api=events&startDate=${startDate}&endDate=${endDate}`);
      const eventdata = await eventresponse.json();

      const newusersresponse = await fetch(`https://selectcards.ai/proxy.php?api=newusers&startDate=${startDate}&endDate=${endDate}`);
      const newusersdata = await newusersresponse.json();

      setAnalyticsData(data);
      setCountryData(countrydata);
      setDeviceData(devicedata);
      setEventData(eventdata);
      setNewUserData(newusersdata);
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbarmessage('Error Fetching the Data from the server');
      setSnackbaropen(true);
    } finally {
      setModalloading(false);
      setModalopen(false);
    }
  }
  // DatewiseData



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const blogData = {
      title,
      paragraphs,
      image,
    };

    try {
      const response = await fetch('https://selectcards.ai/blogpost.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(blogData),
      });

      if (!response.ok) {
        throw new Error('Failed to create blog post');
      }

      const data = await response.json();
      console.log(data);
      alert('Blog Posted Successfully');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddParagraph = () => {
    setParagraphs((prevParagraphs) => [
      ...prevParagraphs,
      {
        text: '',
        backlinks: [
          {
            text: '',
            url: '',
          },
        ],
      },
    ]);
    // const paragraph = {
    //   text: "",
    //   backlinks: []
    // };
    // blogmodaldata.paragraphs.push(paragraph);
    // setBlodModaldata({
    //   ...blogmodaldata,
    //   paragraphs: blogmodaldata.paragraphs
    // });
  };

  const handleRemoveParagraph = (index) => {
    setParagraphs((prevParagraphs) => prevParagraphs.filter((paragraph, i) => i !== index));
    const paragraph = blogmodaldata.paragraphs[index];
    blogmodaldata.paragraphs.splice(index, 1);
    setBlodModaldata({
      ...blogmodaldata,
      paragraphs: blogmodaldata.paragraphs.filter((_, i) => i !== index)
    });
  };

  const handleUpdateParagraphText = (index, text) => {
    setParagraphs((prevParagraphs) =>
      prevParagraphs.map((paragraph, i) => (i === index ? { ...paragraph, text } : paragraph))
    );
  };

  const seperateblogUpdateParagraph = (index, text) => {
    setBlodModaldata((prevData) => ({
      ...prevData,
      paragraphs: prevData.paragraphs.map((paragraph, i) => (i === index ? { ...paragraph, text } : paragraph))
    }));
  };

  const handleUpdateBacklinkText = (paragraphIndex, backlinkIndex, text) => {
    setParagraphs((prevParagraphs) =>
      prevParagraphs.map((paragraph, i) =>
        i === paragraphIndex
          ? {
            ...paragraph,
            backlinks: paragraph.backlinks.map((backlink, j) =>
              j === backlinkIndex ? { ...backlink, text } : backlink
            ),
          }
          : paragraph
      )
    );
  };

  const handleUpdateBacklinkUrl = (paragraphIndex, backlinkIndex, url) => {
    setParagraphs((prevParagraphs) =>
      prevParagraphs.map((paragraph, i) =>
        i === paragraphIndex
          ? {
            ...paragraph,
            backlinks: paragraph.backlinks.map((backlink, j) =>
              j === backlinkIndex ? { ...backlink, url } : backlink
            ),
          }
          : paragraph
      )
    );
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result.split(',')[1]; // Extract the base64 encoded string
      setImage(base64Image);
    };
    reader.readAsDataURL(file);
  };

  // deleting the blog
  const handledeleteblog = async (id) => {
    try {
      const response = await fetch('https://selectcards.ai/blogdelete.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }), // Send the ID in the request body
      });
  
      if (response.ok) {
        alert('Blog deleted successfully');
        // You can update the state to remove the deleted blog from the list
        // For example:
        // setBlogs(blogs.filter((blog) => blog.id !== id));
      } else {
        console.error('Error deleting blog:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };
  
  // deleting the blog


  // Report Download

  //   const DownloadDatewiseData = async () => {
  //     const startDate = document.getElementById('startDate').value;
  //     const endDate = document.getElementById('endDate').value;

  //     try {
  //         const response = await fetch(`http://localhost:5000/api/analytics/download/pdf?startDate=${startDate}&endDate=${endDate}`, {
  //             method: 'GET',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //             },
  //         });

  //         if (!response.ok) {
  //             throw new Error('Network response was not ok');
  //         }

  //         // Create a Blob from the response
  //         const blob = await response.blob();

  //         // Create a link element
  //         const link = document.createElement('a');
  //         link.href = window.URL.createObjectURL(blob);
  //         link.download = 'analytics_report.pdf'; // Set the file name

  //         // Append to the body (required for Firefox)
  //         document.body.appendChild(link);

  //         // Programmatically click the link to trigger the download
  //         link.click();

  //         // Clean up and remove the link
  //         document.body.removeChild(link);
  //     } catch (error) {
  //         console.error('Error downloading the file:', error);
  //     }
  // };
  // ReportDownload

  return (
    <>
      <Snackbar
        open={sanckbaropen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarmessage}
        action={action}
      />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" className='bg-white p-2' open={open}>
          <Toolbar>
            <IconButton
              color="black"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div">
              Welcome to the Dashboard!
            </Typography> */}
            <img src={logo} alt='Logo' style={{ width: "130px", height: "40px" }} className='img-fluid' />
            <div className="ms-auto">
              <Link to='/'><Button variant='contained' sx={{ backgroundColor: "red" }}><LogoutIcon /> Logout</Button></Link>
            </div>
          </Toolbar>

        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {['Dashboard', 'Services', 'Testimonials', 'Blog'].map((text) => {
              // Define the icon mapping
              const iconMapping = {
                Dashboard: <DashboardIcon />,
                Services: <BuildIcon />,
                Testimonials: <StarIcon />,
                Blog: <ArticleIcon />,
              };

              return (
                <ListItem key={text} disablePadding sx={{ display: 'block', marginTop: "10px" }}>
                  <ListItemButton
                    onClick={() => setContent(text)} // Update content on click
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      backgroundColor: content === text ? 'rgb(25,118,210)' : 'transparent', // Change background color
                      color: content === text ? 'white' : 'inherit',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: content === text ? 'white' : 'inherit',
                      }}
                    >
                      {iconMapping[text]} {/* Render icon based on text */}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              );
            })}
            <hr />
            {['Contact', 'Enquiry', 'FormData'].map((text) => {
              // Define the icon mapping
              const iconMapping = {
                Contact: <ContactsIcon />,
                Enquiry: <ContactSupportIcon />,
                FormData: <ContactEmergencyIcon />,
              };

              return (
                <ListItem key={text} disablePadding sx={{ display: 'block', marginTop: "10px" }}>
                  <ListItemButton
                    onClick={() => setContent(text)} // Update content on click
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      backgroundColor: content === text ? 'rgb(25,118,210)' : 'transparent', // Change background color
                      color: content === text ? 'white' : 'inherit',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: content === text ? 'white' : 'inherit',
                      }}
                    >
                      {iconMapping[text]} {/* Render icon based on text */}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {/* Conditionally render content based on the state */}
          {content === 'Dashboard' && (
            <>
              <div className='container-fluid' style={{ paddingTop: "100px" }}>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 text-start'>
                    <div>
                      <Typography variant='h5' sx={{ paddingBottom: "20px", color: "gray" }}>Select Cards Report</Typography>

                    </div>
                    
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                  <div className='d-flex'>
                      <div>
                        <Typography>Start Date:</Typography>
                        <TextField variant='standard' type="date" id="startDate" name="startDate" required />
                      </div>
                      <div>
                        <Typography className='ms-3'>End Date:</Typography>
                        <TextField variant='standard' className='ms-3' type="date" id="endDate" name="endDate" required />
                      </div>
                      <div>
                        <Button type="submit" className='ms-3' variant='contained' onClick={FetchDatewisedata}>Fetch Analytics</Button>
                      </div>
                    </div>
                  </div>
                  {modalloading ? (<Modal
                    open={modalopen}
                    onClose={modalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '8vh', // Full height of the viewport
                        width: '5vw', // Full width of the viewport
                        backgroundColor: 'rgba(255,255,255)',
                      }}
                      className="rounded"
                    >
                      <CircularProgress />
                    </Box>
                  </Modal>) : (
                    <>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-5'>
                        {analyticsData && <AnalyticsChart data={analyticsData} />}
                      </div>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-5'>
                        {countryData && <CountryChart data={countryData} />}
                      </div>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mt-5'>
                        {deviceData && <DeviceChart data={deviceData} />}
                      </div>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 mt-5'>
                        {eventData && <EventChart events={eventData} />}
                      </div>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-5'>
                        {useractiviy && <UserActivityChart data={useractiviy} />}
                      </div>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-5'>
                        {newuserdata && <NewUsersChart data={newuserdata} />}
                      </div>
                    </>
                  )}
                </div>
              </div>


              {/* <div className="dashboard">
                <h1>Website Analytics Dashboard</h1>
                <div className="stats">
                  <div className="stat">
                    <h3>Active Users</h3>
                    <p>{activeUsers}</p>
                  </div>
                  <div className="stat">
                    <h3>Website Clicks</h3>
                    <p>{websiteClicks}</p>
                  </div>
                  <div className="stat">
                    <h3>Monthly Traffic</h3>
                    <p>{monthlyTraffic}</p>
                  </div>
                  <div className="stat">
                    <h3>Conversion Rates</h3>
                    <p>{conversionRates}%</p>
                  </div>
                  <div className="stat">
                    <h3>Avg Session Duration</h3>
                    <p>{avgSessionDuration} mins</p>
                  </div>
                </div>

                <div className="chart">
                  <h3>Monthly Traffic</h3>
                  <Line data={trafficChartData} />
                </div>
              </div> */}
            </>
          )}
          {content === 'Services' && <Typography variant="h4">Services Content</Typography>}
          {content === 'Testimonials' && <Typography variant="h4">Testimonials Content</Typography>}
          {content === 'Blog' &&
            (
              <>
                <Typography variant="h4">Blog Content</Typography>
                <div className='container-fluid mt-5'>
                  <div className="row">
                    <div className='col-12'>
                      <Typography variant='h4'>Blogs</Typography>
                      <div className='text-end'>
                        <Button variant='contained' onClick={() => setBlogOpen(true)}><AddCircleIcon />  Add Blog</Button>
                      </div>
                      <Modal
                        open={blogopen}
                        onClose={() => setBlogOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                      >
                        <Box sx={style}>
                          <Typography variant='h5' className='text-center'><u>Create A Blog</u></Typography>
                          <form onSubmit={handleSubmit} className='mt-3'>
                            <Typography>Title</Typography>
                            <TextField type="text" variant='outlined' value={title} onChange={(event) => setTitle(event.target.value)} />
                            <Typography style={{ marginTop: "10px" }}>
                              Image:
                            </Typography>
                            <input type="file" onChange={handleImageChange} />
                            {paragraphs.map((paragraph, index) => (
                              <div key={index} className='mt-3'>
                                <Typography>
                                  Paragraph {index + 1}:
                                </Typography>
                                <FormControl>
                                  <textarea
                                    value={paragraph.text}
                                    onChange={(event) => handleUpdateParagraphText(index, event.target.value)}
                                    className='form-control'
                                    style={{ fontSize: "10px", width: "400px", height: "200px" }}
                                  />
                                </FormControl>
                                <Button type="button" variant='text' onClick={() => handleRemoveParagraph(index)}
                                  style={{ textTransform: "none", color: "red" }}>
                                  Remove
                                </Button>
                              </div>
                            ))}
                            <Button type="button" onClick={handleAddParagraph}>
                              Add Paragraph
                            </Button>
                            <div className='text-end'>
                              <Button type="submit" disabled={loading} style={{ color: "#be2aed" }}>
                                Create Blog Post
                              </Button>
                            </div>

                          </form>
                        </Box>
                      </Modal>
                    </div>
                    <div className='row pt-5'>
                      {blogPost.map((post, index) => (
                        <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4' key={index}>
                          <Card style={{ marginBottom: "20px", padding: "20px", border: "1px solid #ccc", borderRadius: "10px" }}>
                            <CardContent>
                              <img src={`data:image/jpeg;base64,${post.image}`} className='img-fluid' />
                              <h3 className='text-center mt-2'>{post.title}</h3>
                              {post.paragraphs.map((paragraph, paragraphIndex) => (
                                <div key={paragraphIndex}>
                                  <p dangerouslySetInnerHTML={{ __html: paragraph.text.substring(0, 30) + '...' }} />
                                </div>
                              ))}
                            </CardContent>
                            <CardActions>
                              <Button size="small" variant='text' onClick={() => handledeleteblog(post.id)}><DeleteIcon style={{ color: "red" }} /></Button>
                              <Button size="small" variant='text' onClick={() => seperateblogmodal(post.id)}>Read More</Button>
                            </CardActions>
                          </Card>
                        </div>
                      ))}
                    </div>
                    <div>
                      <Modal
                        open={blogmodal}
                        onClose={() => setBlogModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          {blogmodaldata && blogmodaldata.paragraphs && (
                            <>
                              <img
                                src={`data:image/png;base64,${blogmodaldata.image}`}
                                alt={blogmodaldata.title || 'Blog Image'}
                                style={{
                                  width: '100%',
                                  height: 'auto', // Set desired height
                                  objectFit: 'contain', // Ensure image doesn't stretch
                                }}
                              />
                              <Typography variant="h5" component="div" className='mt-2 text-center'>{blogmodaldata.title}</Typography>
                              {blogmodaldata.paragraphs.map((paragraph, index) => (
                                <div key={index} className='mt-3'>
                                  <Typography>
                                    Paragraph {index + 1}:
                                  </Typography>
                                  <FormControl>
                                    <textarea
                                      value={paragraph.text}
                                      onChange={(event) => seperateblogUpdateParagraph(index, event.target.value)}
                                      className='form-control'
                                      style={{ fontSize: "10px", width: "500px", height: "150px", overflowY: "auto" }}
                                    />
                                  </FormControl>
                                </div>
                              ))}
                              <div className='text-end mt-3'>
                                <Button variant='text' sx={{ color: "orange" }} onClick={() => updateblogs(blogmodaldata.id)}>Update</Button>
                                <Button variant='text' sx={{ color: "purple" }} onClick={() => setBlogModal(false)}>Close</Button>
                              </div>
                            </>
                          )}
                        </Box>
                      </Modal>
                    </div>
                  </div>
                </div>
              </>
            )}
          {content === 'Contact' &&
            (
              <>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-12 text-center' style={{ paddingTop: "90px" }}>
                      <Typography variant='h4'>Contact Data</Typography>
                    </div>
                    <div className='col-12'>
                      <div style={{ marginBottom: '20px', display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                        <TextField
                          value={contactnameFilter}
                          onChange={(e) => setContactNameFilter(e.target.value)}
                          label="Filter by Name"
                          variant="standard"
                          type='text'
                        />
                        <TextField
                          value={contactemailFilter}
                          onChange={(e) => setContactEmailFilter(e.target.value)}
                          label="Filter by Email"
                          variant="standard"
                          type='email'
                        />
                        <TextField
                          value={contactdateFilter}
                          onChange={(e) => setContactDateFilter(e.target.value)}
                          label=""
                          variant="standard"
                          type='date'
                          style={{ marginTop: "13px" }}
                        />
                        <div>
                          <Button variant="contained" onClick={ContacthandleSearch} >Search</Button>
                          <Button variant="contained" onClick={handleReset} className='ms-2 bg-warning'>Reset</Button>
                          <Button
                            variant="contained"
                            className="ms-2"
                            onClick={handleGenerateCSV}
                            style={{ backgroundColor: "#4CAF50", color: "white" }}
                          >
                            Generate CSV
                          </Button>
                        </div>
                      </div>
                      <TableContainer component={Paper} style={{ backgroundColor: "#ECECEC", maxHeight: "400px" }}>
                        <Table stickyHeader>
                          {/* Table Head */}
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>ID</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Name</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Email Address</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Mobile No</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Employment Status</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Pincode</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Date&Time</TableCell>
                              <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Action</TableCell>
                            </TableRow>
                          </TableHead>

                          {/* Table Body */}
                          <TableBody>
                            {Array.isArray(filteredContactData) && filteredContactData.length > 0 ? (
                              filteredContactData.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.ID}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.customername}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.email}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.mobileno}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.employmentstatus}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.highestlimit}</TableCell>
                                  <TableCell sx={{ fontSize: "12px" }}>{row.DateTime}</TableCell>
                                  <TableCell>
                                    <Button variant='text' onClick={() => deleteRecord(row.ID)}><DeleteIcon sx={{ color: "red", fontSize: "18px" }} /></Button>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={11} align="center">
                                  No Data Available
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </div>
                  </div>
                </div>
              </>
            )}
          {content === 'Enquiry' &&
            (
              <>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <div className='card'>
                      <div className='card-body'>
                        <CircularProgress />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-12 text-center' style={{ paddingTop: "90px" }}>
                        <Typography variant='h4'>Enquiry Data</Typography>
                      </div>
                      <div className='col-12'>
                        <div style={{ marginBottom: '20px', display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                          <TextField
                            label="Filter by Name"
                            value={nameFilter}
                            onChange={(e) => setNameFilter(e.target.value)}
                            variant="standard"
                            type='text'
                          />
                          <TextField
                            label="Filter by Email"
                            value={emailFilter}
                            onChange={(e) => setEmailFilter(e.target.value)}
                            variant="standard"
                            type='email'
                          />
                          <TextField
                            label=""
                            value={dateFilter}
                            onChange={(e) => setDateFilter(e.target.value)}
                            variant="standard"
                            type='date'
                            style={{ marginTop: "13px" }}
                          />
                          <div>
                            <Button variant="contained" onClick={handleSearch} >Search</Button>
                            <Button variant="contained" className='ms-2 bg-warning' onClick={resetFilters}>Reset</Button>
                            <Button
                              variant="contained"
                              className="ms-2"
                              onClick={handleGenerateEnquiryCSV}
                              style={{ backgroundColor: "#4CAF50", color: "white" }}
                            >
                              Generate CSV
                            </Button>
                          </div>
                        </div>
                        <TableContainer component={Paper} style={{ backgroundColor: "#ECECEC", maxHeight: "300px" }}>
                          <Table stickyHeader>
                            {/* Table Head */}
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>ID</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Name</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Email Address</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Mobile No</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Date&Time</TableCell>
                                <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            {/* Table Body */}
                            <TableBody>
                              {Array.isArray(filteredEnquiryData) && filteredEnquiryData.length > 0 ? (
                                filteredEnquiryData.map((row, index) => (
                                  <TableRow key={index}>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.id}</TableCell>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.customername}</TableCell>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.email}</TableCell>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.mobileno}</TableCell>
                                    <TableCell sx={{ fontSize: "12px" }}>{row.datetime}</TableCell>
                                    <TableCell>
                                      <Button onClick={() => handleDelete(row.id)}>
                                        <DeleteIcon sx={{ color: "red", fontSize: "18px" }} />
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={6} align="center">
                                    No Data Available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          }
          {content === 'FormData' && (
            <>
              <h2 className='text-center' style={{ marginTop: "100px" }}><u>Form Data</u></h2>
              <div className="container">
                <div className='row'>
                  <div className='col-12'>
                  <div style={{ marginBottom: '20px', display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                          <TextField
                            label="Filter by Name"
                            value={formnamefilter}
                            onChange={(e) => setFormNameFilter(e.target.value)}
                            variant="standard"
                            type='text'
                          />
                          <TextField
                            label="Filter by Email"
                            value={formemailfilter}
                            onChange={(e) => setFormEmailFilter(e.target.value)}
                            variant="standard"
                            type='email'
                          />
                          <TextField
                            label=""
                            value={formdatefilter}
                            onChange={(e) => setFormDateFilter(e.target.value)}
                            variant="standard"
                            type='date'
                            style={{ marginTop: "13px" }}
                          />
                          
                          <div>
                            <Button variant="contained" onClick={FormhandleSearch} >Search</Button>
                            <Button variant="contained" className='ms-2 bg-warning' onClick={handleformReset}>Reset</Button>
                            <Button
                              variant="contained"
                              className="ms-2"
                              onClick={handleGenerateFormCSV}
                              style={{ backgroundColor: "#4CAF50", color: "white" }}
                            >
                              Generate CSV
                            </Button>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
              <TableContainer component={Paper} style={{ backgroundColor: "#ECECEC", maxHeight: "500px", marginTop: "40px" }}>
                <Table stickyHeader>
                  {/* Table Head */}
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>ID</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Name</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Email Address</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Mobile No</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Employment status</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Monthly Income</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Highest Limit Card</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Looking For</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Existing Loans</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Online Spends</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Offline Spends</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Categories</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Date & Time</TableCell>
                      <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "#ECECEC", zIndex: 1 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Table Body */}
                  <TableBody>
                  {Array.isArray(filteredformdata) && filteredformdata.length > 0 ? (
                                filteredformdata.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ fontSize: "12px" }}>{row.id}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.customername}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.email}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.mobileno}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.employmentstatus}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.monthlyincome}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.highestlimit}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.lookingfor}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.existingLoans}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.onlinespendings}%</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.offlinespendings}%</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.lifestyle}</TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>{row.DateTime}</TableCell>
                          <TableCell>
                            <Button onClick={() => handleformDelete(row.id)}>
                              <DeleteIcon sx={{ color: "red", fontSize: "18px" }} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} align="center" sx={{ fontSize: "14px", fontStyle: "italic", color: "gray" }}>
                          No data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Box >
    </>
  );
}
