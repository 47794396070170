import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Icici from './images/card/icici/icici.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';


function Icicibank() {
  const [isExpanded, setIsExpanded] = useState(false);

  const fullText = `With numerous ICICI Bank credit card options available, it can be overwhelming for individuals to sift through all the features and benefits each card offers. To make the process simpler, we’ve curated a list of the best ICICI credit cards available in India. This guide allows you to quickly compare the cards based on their benefits, rewards, and unique features. Whether you're looking for cashback, travel perks, or premium privileges, our list will help you make an informed decision and apply for the card that best suits your needs and lifestyle.`;

  const truncatedText = fullText.slice(0, Math.floor(fullText.length / 2)); // Display half of the text
  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <p className="mt-3 text-justify ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
              {isExpanded ? fullText : `${truncatedText}...`}
            </p>
            <div className="text-center">
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                style={{
                  backgroundColor: "#1976D3",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  padding: "5px 15px",
                  cursor: "pointer",
                }}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <div className="d-lg-flex justify-content-between">
              <div className="text-center p-5">
                <img src={Icici} className="img-fluid" style={{ height: "180px" }} title="ICICI Credit Card" />
                <h6 gutterBottom className="pt-3 fw-bold" component="div">
                  ICICI Credit Card
                </h6>
              </div>
              <div className="mt-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                <p>
                  Product Benefits:
                </p>
                <p variant="body2">
                  <ArrowRightIcon />   Enjoy discounts on shopping, dining, movies, and more
                </p>
                <p variant="body2">
                  <ArrowRightIcon />  Enjoy cash rewards and ICICI bank rewards offer
                </p>
                <p variant="body2">
                  <ArrowRightIcon />  Get complimentary access to airport lounges
                </p>
                <p variant="body2">
                  <ArrowRightIcon />   Discounts on fuel purchases and waiver of fuel surcharge
                </p>
                <p variant="body2">
                  <ArrowRightIcon />  No joining and annual fee on selected variants
                </p>

              </div>
              <div>
                <a href="https://indiasales.club/TBKH0476/HA2N2699/IK" style={{ textDecoration: "none" }} target="_blank"><Card className=" ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>
              </div>
            </div>
          </div>

          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Icicibank;