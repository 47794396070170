import React from 'react';
import ReactDOM from 'react-dom/client';
import pic from './images/navlogo.png';
import bg from './images/bg.png';
import './Navbar.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Typography } from "@mui/material";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Submit from './Submitform';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import Airtelpayment from './Debitcards';
import './index.css';


const Navbar = () => {

    const location = useLocation();
    const isAdminPage = location.pathname === '/admin';
    // Check if the current path is '/admin'

    // If it's the admin page, do not render the navbar
    if (isAdminPage) return null;

    const handleNavbarButton = () => {
        const button = document.getElementById("navbarbutton");
        button.classList.toggle("open"); // Toggle 'open' class on each click
    };


    return (
        <>

            <nav class="navbar navbar-expand-lg fixed-top" style={{ backgroundImage: `url(${bg})`, fontFamily: "Satoshi", position: "fixed", top: "0", width: "100%", zIndex: "1000" }} >                <div class="container-fluid">
                <Link class="navbar-brand" to='/'><img src={pic} alt="Logo" style={{ height: "60px" }} className="Navimage" /></Link>
                <span
                    onClick={handleNavbarButton}
                    className="navbar-toggler"
                    id="navbarbutton"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <MenuIcon sx={{ color: "white" }} className="menu-icon" />
                    <CloseIcon sx={{ color: "white" }} className="close-icon" />
                </span>                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li class="nav-item mt-lg-2 mt-0">
                            <Link smooth to='/' className='nav-link active' style={{ textDecoration: "none", color: "white" }}>Home</Link>
                        </li>
                        <li className="nav-item mt-lg-2 mt-0 dropdown">
                            <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                About Us
                            </a>
                            <ul class="dropdown-menu">
                                <li><Link class="dropdown-item" to='/companyoverview'>Company overview</Link></li>
                                <li><Link class="dropdown-item" to='/howitworks'>How it works</Link></li>

                            </ul>
                        </li>
                        <li className="nav-item mt-lg-2 mt-0 dropdown">
                            <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Cards
                            </a>
                            <ul class="dropdown-menu">
                                <li><Link class="dropdown-item" to='/creditcards'>Credit Cards</Link></li>
                                <li><Link class="dropdown-item" to='/prepaid'>Prepaid Cards</Link></li>
                                <li><Link class="dropdown-item" to='/debitcards' >Debit Cards</Link></li>
                            </ul>
                        </li>

                        <li className='nav-item mt-lg-2 mt-0'>
                            <Link to='/blog' className='nav-link active text-white'>Blogs</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/getintouch' className='nav-link active text-dark'><Button style={{ backgroundColor: "white", color: "black", borderRadius: "30px", padding: "10px", fontSize: "14.07px" }}>GET IN TOUCH</Button></Link>
                        </li>
                    </ul>
                </div>
            </div>
            </nav>
        </>
    )
}

export default Navbar;