import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import bg from './images/bg.png';
import Footer from './Footer';
import Whatsapp from './whatsapp';
import ReactDOM from 'react-dom';

const SeperateBlog = () => {
    const { id } = useParams();
    const [blogPost, setBlogPost] = useState({});

    useEffect(() => {
        const fetchBlogPost = async () => {
            try {
                const response = await fetch(`https://selectcards.ai/getblogbyid.php?id=${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch blog post');
                }
                const data = await response.json();
                setBlogPost(data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchBlogPost();
    }, [id]);

    return (
        <div style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})`, color: "white" }}>
        <div className='container-fluid'>
            <div className='row' style={{ paddingTop: "80px" }}>
                <div className='col-12 p-4'>
                    <img src={`data:image/jpeg;base64,${blogPost.image}`} className='img-fluid rounded' />
                    <h1 className='text-center mt-4'><u>{blogPost.title}</u></h1>
                    {blogPost.paragraphs && blogPost.paragraphs.map((paragraph,paragraphIndex) => (
                        <div key={paragraphIndex} className='text-justify'>
                        <p dangerouslySetInnerHTML={{ __html: paragraph.text }} />
                        </div>
                   ))}
                    {/* {blogPost.paragraphs && blogPost.paragraphs.map((paragraph, paragraphIndex) => (
                        <div key={paragraphIndex} className='text-justify'>
                            {paragraph.text.replace(/</g, '&lt;').replace(/>/g, '&gt;')}
                        </div>
                    ))} */}
                </div>
            </div>
        </div>

        {/* Footer */}
        <Footer/>
        {/* Footer */}

        {/* whatsapp */}
        <Whatsapp/>
        {/* whatsapp */}
        </div>
    );
};

export default SeperateBlog;