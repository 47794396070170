import React from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import logo from './images/navlogo.png';
import fb from './images/ICONS/fb.png';
import whatsapp from './images/ICONS/whatsapp.png';
import linkedin from './images/ICONS/linkdin.png';
import twitter from './images/ICONS/X.png';
import insta from './images/ICONS/INSTA.png';
import './Footer.css';


const Footer = () => {
  const [Name, setName] = React.useState('');
  const [Email, setEmail] = React.useState('');
  const [MobileNo, setMobileNo] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [snackbarmessage, setSnackbarmessage] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const Submit = async (e) => {
    e.preventDefault();

    const formData = {
      customername: Name,
      email: Email,
      mobileno: MobileNo,
    };

    try {
      const response = await fetch('https://selectcards.in/database.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert the form data to JSON
      });

      if (response.ok) {
        const result = await response.json();
        setSnackbarmessage('Enquiry Submitted Successfully');
        setOpen(true);
        setName('');
        setEmail('');
        setMobileNo('');
      } else {
        console.error('Error:', response.statusText);
        setSnackbarmessage('Failed to submit form. Please try again.');
        setOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarmessage('An error occurred. Please check the console for details.');
      setOpen(true);
    }

  }
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={<IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ backgroundColor: 'white', color: 'green' }}>
          {snackbarmessage}
        </Alert>
      </Snackbar>
      <div className="container-fluid" style={{
        borderRadius: "30px 30px 0px 0px",
        background: "linear-gradient(to bottom, #000000, #000000)", // Glass-like gradient
        backdropFilter: "blur(3px)", fontFamily: "Satoshi"
      }}>
        <div className="row p-5">
          <div className="col-12">
            <img src={logo} className="imgsize" />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pt-4" id="contact">
            <p className="footertext">Choosing the right card doesn’t have to be complicated. Let Select Cards and our AI-driven system help you find the perfect match for your financial goals. Start now to get your personalized recommendations and unlock better rewards, savings, and perks!</p>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 pt-4">
            <div className="companydetails">
              <div>
                <p><MailOutlineIcon /> info@selectcards.in</p>
              </div>
              <div>
                <p><PhoneIcon /> +91 9550581859</p>
              </div>
              <div>
                <p><AccessTimeIcon /> Mon-Sat 10 AM - 6 PM</p>
              </div>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="row">
              <div className="col-12">
                <hr style={{ border: "none", borderTop: "2px dashed #C7CCE7", margin: "20px 0" }} />
              </div>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="row">
              <div className="col-12 d-md-flex justify-content-between">
                <div className="icontext">
                  <p>Follow Us On:</p>
                </div>
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <a href="https://www.facebook.com/profile.php?id=61568955067891" target="_blank" className="mx-2"><img src={fb} /></a>
                    </div>
                    <div>
                      <a href="https://wa.me/919550581859" target="_blank" className="mx-2"><img src={whatsapp} /></a>
                    </div>
                    <div>
                      <a href="https://www.linkedin.com/company/select-cards/?viewAsMember=true" target="_blank" className="mx-2"><img src={linkedin} /></a>
                    </div>
                    <div>
                      <a href="https://x.com/select_cards" target="_blank" className="mx-2"><img src={twitter} /></a>
                    </div>
                    <div>
                      <a href="https://www.instagram.com/selectcards.ai/" target="_blank" className="mx-2"><img src={insta} /></a>
                    </div>
                  </div>
                </div>
                <div className="copytext">
                  <p>@CollectIntel</p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  )
}

export default Footer;