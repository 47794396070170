import React,{useState} from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import HPCL from './images/card/idfc/HPCL.png';
import Swyp from './images/card/idfc/swyp.jpeg';
import Idfcfirst from './images/card/idfc/IDFCFIRST.webp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';


function Idfcbank() {
  const [isExpanded, setIsExpanded] = useState(false);

  const fullText = `            IDFC First Bank stepped into the credit card segment in January 2021, offering a range of cards tailored to suit both premium and everyday customers. Designed to cater to diverse needs, these cards stand out for their zero membership fees, making them an excellent choice for those seeking cost-effective options. Alongside affordability, IDFC First Bank Credit Cards deliver exceptional value with their high accelerated reward rates, allowing you to earn more on your everyday spends. Whether you’re looking for travel benefits, shopping rewards, or a card that combines simplicity with great perks, there’s something for everyone. Here’s a detailed look at the top credit cards from IDFC First Bank to help you pick the perfect one for your lifestyle.`;

  const truncatedText = fullText.slice(0, Math.floor(fullText.length / 2)); // Display half of the text

  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <p className="mt-3 text-justify ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
            {isExpanded ? fullText : `${truncatedText}...`}
            </p>
            <div className="text-center">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            backgroundColor: "#1976D3",
            color: "white",
            border: "none",
            borderRadius: "5px",
            padding: "5px 15px",
            cursor: "pointer",
          }}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      </div>
          </div>
          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={Idfcfirst} className="img-fluid mt-5" title="Idfc First Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                      IDFC FIRST Bank Credit Card
                    </h6>
                  </div>
                  <div className="mt-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                    <p>
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Lifetime Free Credit Card
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Welcome voucher worth ₹500 on spending ₹5000 within first 30 days
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Reward Points <br />- 3X Rewards on Online and Offline spends <br /> - 10X Rewards on monthly spends above ₹20,000 <br /> - 1X Rewards on insurance and utility transactions <br /> - No Expiry
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Additional benefits <br /> - 25% Off at movie tickets once a month (via PayTM) <br /> - Free road side assistance worth ₹1499 <br /> - Up to 20% discount across 1500+ restaurants and merchants
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon /> Insurance protection: Personal Accident Cover, Lost Card Liability Cover
                    </p>

                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/IF" style={{ textDecoration: "none" }} target="_blank"><Card className=" ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>
                  </div>
                </div>
          </div>

          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={Swyp} style={{ height: "auto" }} className="img-fluid" title="IDFC FIRST SWYP Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                      IDFC FIRST SWYP Credit Card
                    </h6>
                  </div>
                  <div className="mt-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                    <p>
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Discount coupon of ₹2,100 from EaseMyTrip
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Up to 20% discount on Domino’s, Zomato, TataCliq and many more
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />    2000 RPs on spending ₹5000 within first 30 days
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  1000 RPs on 1st EMI Transaction within first 30 days
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  25% discount on movie tickets on Paytm
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Complimentary Railway Lounge Access
                    </p>

                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/PY" style={{ textDecoration: "none" }} target="_blank"><Card className="ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>
                  </div>
                </div>


              
          </div>

          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={HPCL} style={{ height: "180px" }} className="img-fluid" title="IDFC FIRST HPCL Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                      IDFC FIRST HPCL Credit Card
                    </h6>
                  </div>
                  <div className="mt-4" style={{ color: "text.secondary", fontSize: "16px" }}>
                    <p>
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Welcome benefits worth ₹1250 to ₹1500(Based on variant)
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   UPI Rupay Credit Card
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Up to 30x rewards on Fuel and FASTag
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   1% Cahback on all other spends
                    </p>
                    <p variant="body2" >
                      <ArrowRightIcon />    0 Annual Fee on spending = ₹50K /1.50 Lacs in previous year (Based on variant)*
                    </p>

                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/FL" style={{ textDecoration: "none" }} target="_blank"><Card className="ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>
                  </div>
                </div>

          </div>

          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Idfcbank;