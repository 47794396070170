import React from "react";
import bg from './images/bg.png';
import './getintouch.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Footer from "./Footer";
import Whatsapp from "./whatsapp";
const Getintouch = () => {
    const [Name, setName] = React.useState('');
    const [Email, setEmail] = React.useState('');
    const [Phone, setPhone] = React.useState('');
    const [DateTime, setDateTime] = React.useState('');

    const submitform = async () => {
        const formData = {
            customername: Name,
            email: Email,
            mobileno: Phone,
            DateTime: DateTime
        };

        try {
            const response = await fetch('https://selectcards.ai/database.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData), // Convert the form data to JSON
            });

            const crm=await fetch('https://a1c0725b-f054-493d-bf2a-ac9f5851bb2f.neodove.com/integration/custom/ccc9327b-160a-42d7-a6fe-7bcb0b44c007/leads',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    },
                    body:JSON.stringify(formData),
            });

            if (response.ok && crm.ok) {
                const result = await response.json();
                alert('Form submitted successfully');
                setName('');
                setEmail('');
                setPhone('');
                setDateTime('');
            } else {
                console.error('Error:', response.statusText);

            }
        } catch (error) {
            console.error('Error:', error);

        }
    }
    return (
        <div style={{ backgroundImage: `url(${bg})`, paddingTop: "70px" }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <p className="heading">Get in Touch</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-lg-5 p-3">
                        <div className="container my-auto">
                            <div className="row">
                                <div className="col-12">
                                    <p className="quotation">"We’re available on     other platforms
                                        let’s get connected!"</p>
                                </div>
                            </div>
                            <div className="row quotationicon">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <CallIcon sx={{ color: "white" }} />
                                        </div>
                                        <div>
                                            <WhatsAppIcon sx={{ color: "white" }} />
                                        </div>
                                        <div>
                                            <MailOutlineIcon sx={{ color: "white" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-lg-5 p-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-10 mx-auto">
                                    <form className="formbox">
                                        <div>
                                            <span className="formtext">Name*</span>
                                            <br />
                                            <TextField type="text" id="standard-basic" value={Name} onChange={(e) => setName(e.target.value)} className="my-text-field" variant="standard" required />
                                        </div>
                                        <div className="formtext">
                                            <span>Email*</span>
                                            <br />
                                            <TextField type="email" id="standard-basic" value={Email} onChange={(e) => setEmail(e.target.value)} className="my-text-field" variant="standard" required />
                                        </div>
                                        <div className="formtext">
                                            <span className="formtext">Mobile*</span>
                                            <br />
                                            <TextField type="number" id="standard-basic" value={Phone} onChange={(e) => {
                              const regex = /^\d{0,10}$/; // Matches only up to 10 digits
                              if (regex.test(e.target.value)) {
                                setPhone(e.target.value);
                              }
                            }}
                            inputProps={{
                              inputMode: "numeric", // Ensures numeric keyboard on mobile
                              pattern: "\\d{10}", // Accepts exactly 10 digits
                              maxLength: 10, // Limits the input length to 10 characters
                            }} className="my-text-field" variant="standard" required />
                                        </div>
                                        <div className="formtext">
                                            <span className="formtext">Date & Time*</span>
                                            <br />
                                            <TextField type="datetime-local" id="standard-basic" value={DateTime} onChange={(e) => setDateTime(e.target.value)} label='' className="my-text-field" variant="standard" required />
                                        </div>
                                        <Button variant="contained" onClick={submitform} style={{ fontFamily: "Satoshi", color: "white", backgroundColor: "#0B45C3", fontWeight: "bold", marginTop: "20px" }}>Schedule a Call</Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* whatsapp widget */}
            <Whatsapp />
            {/* whatsap widget */}
        </div>
    )
}

export default Getintouch;