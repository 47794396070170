import React,{useState} from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import axisbank from './images/card/axis/1.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';


function Axisbank() {
  const [isExpanded, setIsExpanded] = useState(false);

  const fullText = `Axis Bank offers a wide range of credit cards, each with its own set of features and benefits, making it challenging for individuals to choose the right one. To simplify this, we’ve created an informative guide covering the top Axis Bank Credit Cards, so you can easily compare them based on factors like rewards, fees, and exclusive privileges. Some of the most popular Axis Bank credit cards include the Flipkart Axis, Select, Ace, and Horizon. For more details on the latest offerings, explore the full range of Axis Bank Credit Cards and find the perfect credit cards match for your needs. Apply today to start enjoying the benefits!            
`;

  const truncatedText = fullText.slice(0, Math.floor(fullText.length / 2)); // Display half of the text
  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <p className="mt-3 text-justify ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
            {isExpanded ? fullText : `${truncatedText}...`}
            </p>  
            <div className="text-center">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            backgroundColor: "#1976D3",
            color: "white",
            border: "none",
            borderRadius: "5px",
            padding: "5px 15px",
            cursor: "pointer",
          }}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      </div>      
            </div>
          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                <div className="d-lg-flex justify-content-between">
                  <div className="text-center p-5">
                    <img src={axisbank} className="img-fluid" style={{ height: "180px",width:"auto" }} title="Axis Bank Credit Card" />
                    <h6 gutterBottom className="pt-3 fw-bold" component="div">
                    Axis Bank Credit Card
                    </h6>
                  </div>
                  <div className="mt-3" style={{ color: "text.secondary", fontSize: "16px" }}>
                    <p className="fw-bold">
                      Product Benefits:
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Choose from multiple credit cards to suit your lifestyle
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Complimentary airport lounge access (on some variants)

                    </p>
                    <p variant="body2">
                      <ArrowRightIcon /> Exciting rewards and cashback benefits
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />    1% Fuel Surcharge Waiver
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Flipkart, Amazon, Zomato, PVR vouchers
                    </p>
                  </div>
                  <div>
                  <a href="https://indiasales.club/TBKH0476/HA2N2699/ACK" style={{ textDecoration: "none" }} target="_blank"><Card className=" ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>
                  </div>
                </div>
                
          </div>

          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Axisbank;