
import axiscard from './images/card/axis/1.png';
import AU from './images/card/AU/1.webp';
import HDFC from './images/card/hdfc/1.png';
import Shoppers from './images/card/hdfc/2.png';
import Tata from './images/card/hdfc/3.png';
import Swiggy from './images/card/hdfc/swiggy.png';
import HPCL from './images/card/idfc/HPCL.png';
import Swyp from './images/card/idfc/swyp.jpeg';
import Idfcfirst from './images/card/idfc/IDFCFIRST.webp';
import Induslandplatinum from './images/card/indus bank/PlatinumCard.png';
import Induslandcredit from './images/card/indus bank/InduslandCreditcard.png';
import Cashbacksbi from './images/card/sbi/SBI.png';
import Irctcsbi from './images/card/sbi/irctcsbi.png';
import Simplysavesbi from './images/card/sbi/simplysave.png';
import Simplyclicksbi from './images/card/sbi/simplyclick.png';
import Icici from './images/card/icici/icici.png';
import Hdfcshoppers from './images/card/hdfc/2.png';
// Debit
import airtelcard from "./images/card/debitcards/airtel.png";
import axisprimarycard from './images/card/debitcards/axisprimary.png';
import axisamazecard from './images/card/debitcards/axis amaze.png';
import idfccard from './images/card/debitcards/idfc.png';
import indiecard from './images/card/debitcards/indie.png';
import indusdelight from './images/card/debitcards/indusdelight.png';
import induslnddebit from './images/card/debitcards/induslnddebit.png';
import tide from "./images/card/debitcards/tide.png";
import anq from './images/card/anq/XCARD.png';

const BusinessCC=[
    {
      "title": "SimplySAVE SBI Credit_Card",
      "imgSrc": Simplysavesbi,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/VS",
      "benefits": [
        "10X Rewards on Movies, Dining & Grocery Spends! ",
        " On Other Spends, Earn 1 Reward Point per ₹150 Spent",
        "2000 Reward Points on Spending ₹2000 in First 60 Days",
        "Annual Fee Reversal on Spending ₹1 Lakh in a Year ",
        "1% Fuel Surcharge Waiver "
      ]
    },
    {
      "title": "IRCTC SBI Platinum Credit_Card",
      "imgSrc": Irctcsbi,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/BZ",
      "benefits": [
        "Earn up to 10% Value Back on Rail ticket purchases",
        "350 bonus Reward Points on activation ",
        "Skip Transaction Charges on bookings at irctc.co.in ",
        " Complimentary railway lounge access ",
        " Earn 1 Reward Point per ₹150 spent*",
        " 1% fuel surcharge waiver"
      ]
    },
    {
      "title": "Axis Bank Credit Card",
      "imgSrc": axiscard,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/ACK",
      "benefits": [
        "Choose from multiple credit cards to suit your lifestyle",
        "Complimentary airport lounge access (on some variants)",
        "Exciting rewards and cashback benefits",
        "1% Fuel Surcharge Waiver",
        "Flipkart, Amazon, Zomato, PVR vouchers"
      ]
    }
  ]

  const RegularCC=[
    // {
    //   "title": "Axis Bank Credit Card",
    //   "imgSrc": axiscard,
    //   "href": "https://indiasales.club/TBKH0476/HA2N2699/ACK",
    //   "benefits": [
    //     "Choose from multiple credit cards to suit your lifestyle",
    //     "Complimentary airport lounge access (on some variants)",
    //     "Exciting rewards and cashback benefits",
    //     "1% Fuel Surcharge Waiver",
    //     "Flipkart, Amazon, Zomato, PVR vouchers"
    //   ]
    // },
    {
      "title": "IndusInd Credit Card ",
      "imgSrc": Induslandcredit,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IC",
      "benefits": [
        " Earn attractive reward points and cashback. ",
        "Reward points that never expire - redeem anytime you want ",
        " Indus Easy Credit Card - lifetime free card ",
        "Enjoy complimentary airport lounge access ",
        " Fuel surcharge on all petrol pumps across India"
      ]
    },
    // {
    //   "title": "AU Small Finance Bank Credit Card",
    //   "imgSrc": AU,
    //   "href": "https://indiasales.club/TBKH0476/HA2N2699/AK",
    //   "benefits": [
    //     "No physical documents required",
    //     "25% discount on movie tickets*",
    //     "Lifetime Free Credit Cards (selected variants)",
    //     "Instant approval",
    //     "Complimentary Airport, Railway Lounge Access",
    //     "Discounts on Zomato, Swiggy and online shopping*"
    //   ]
    // },
    {
      "title": "HDFC Credit Card",
      "imgSrc": HDFC,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/HD",
      "benefits": [
        "Exclusive reward points and cashbacks on online spends",
        "Welcome benefits",
        "Vouchers on monthly spends",
        "Complimentary Airport Lounge access",
        "Fuel surcharge waiver"
      ]
    },
    // {
    //   "title": "ICICI Credit Card",
    //   "imgSrc": Icici,
    //   "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
    //   "benefits": [
    //     "Enjoy discounts on shopping, dining, movies, and more ",
    //     " Enjoy cash rewards and ICICI bank rewards offer ",
    //     " Get complimentary access to airport lounges ",
    //     " Discounts on fuel purchases and waiver of fuel surcharge ",
    //     " No joining and annual fee on selected variants "
    //   ]
    // },
    {
        "title": "IndusInd Platinum RuPay Credit Card",
        "imgSrc": Induslandplatinum,
        "href": "https://indiasales.club/TBKH0476/HA2N2699/DI",
        "benefits": [
          "Lifetime Free Credit Card",
          " UPI payments, now with Credit Card ",
          " Earn 2 Reward Points for every ₹ 100 on non-UPI transactions ",
          " Earn 1 Reward Point for every ₹ 100 on UPI transactions ",
          " 1% Fuel Surcharge Waiver* "
        ]
      },
      // {
      //   "title": "HDFC Bank Shoppers Stop Credit Card",
      //   "imgSrc": Hdfcshoppers,
      //   "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      //   "benefits": [
      //     "₹500 Shoppers Stop voucher on spend of ₹3,000",
      //     " Complimentary Shoppers Stop membership ",
      //     " UPI - Rupay Credit Card ",
      //     " 1% fuel surcharge waiver ",
      //     " Earn 6 First Citizen Points for every ₹150 spent "
      //   ]
      // },
      // {
      //   "title": "Swiggy HDFC Bank Credit Card",
      //   "imgSrc": Swiggy,
      //   "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      //   "benefits": [
      //     "Lifetime Free for limited time",
      //     " 10% Cashback on Swiggy Spends* ",
      //     " 5% Cashback on Online spends* ",
      //     " 1% Cahback on all other spends ",
      //     " Free 3 month Swiggy One membership worth ₹1199* "
      //   ]
      // },
      // {
      //   "title": "IDFC FIRST SWYP Credit Card",
      //   "imgSrc": Swyp,
      //   "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      //   "benefits": [
      //     "Discount coupon of ₹2,100 from EaseMyTrip",
      //     " Up to 20% discount on Domino’s, Zomato, TataCliq and many more ",
      //     " 2000 RPs on spending ₹5000 within first 30 days ",
      //     " 1000 RPs on 1st EMI Transaction within first 30 days ",
      //     " 25% discount on movie tickets on Paytm ",
      //     "Complimentary Railway Lounge Access"
      //   ]
      // },
      // {
      //   "title": "IDFC FIRST HPCL Credit Card",
      //   "imgSrc": HPCL,
      //   "href": "https://indiasales.club/TBKH0476/HA2N2699/FL",
      //   "benefits": [
      //     "Welcome benefits worth ₹1250 to ₹1500(Based on variant)",
      //     " UPI Rupay Credit Card",
      //     " Up to 30x rewards on Fuel and FASTag ",
      //     " 0 Annual Fee on spending >= ₹50K /1.50 Lacs in previous year (Based on variant)* "      
      //   ]
      // }
      // {
      //   "title": "CASHBACK SBI Credit_Card",
      //   "imgSrc": Cashbacksbi,
      //   "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      //   "benefits": [
      //     "5% Cashback on online spends",
      //     " 1% Cashback on offline spends",
      //     " Cashback auto-credit to SBI Card account ",
      //     " Reversal of Renewal Fee on annual spends of Rs.2 Lakh ",
      //     "1% fuel surcharge waiver"      
      //   ]
      // },
      // {
      //   "title": "IRCTC SBI Platinum Credit_Card",
      //   "imgSrc": Irctcsbi,
      //   "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      //   "benefits": [
      //     "Earn up to 10% Value Back on Rail ticket purchases",
      //     " 350 bonus Reward Points on activation",
      //     " Skip Transaction Charges on bookings at irctc.co.in ",
      //     " Complimentary railway lounge access ",
      //     "Earn 1 Reward Point per ₹150 spent*",
      //     "1% fuel surcharge waiver"      
      //   ]
      // }
  ]

  const ExtraCC=[
    {
      "title": "ICICI Credit Card",
      "imgSrc": Icici,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      "benefits": [
        "Enjoy discounts on shopping, dining, movies, and more ",
        " Enjoy cash rewards and ICICI bank rewards offer ",
        " Get complimentary access to airport lounges ",
        " Discounts on fuel purchases and waiver of fuel surcharge ",
        " No joining and annual fee on selected variants "
      ]
    },
    {
        "title": "HDFC Bank Shoppers Stop Credit Card",
        "imgSrc": Hdfcshoppers,
        "href": "https://indiasales.club/TBKH0476/HA2N2699/HC",
        "benefits": [
          "₹500 Shoppers Stop voucher on spend of ₹3,000",
          " Complimentary Shoppers Stop membership ",
          " UPI - Rupay Credit Card ",
          " 1% fuel surcharge waiver ",
          " Earn 6 First Citizen Points for every ₹150 spent "
        ]
      },
      {
        "title": "Swiggy HDFC Bank Credit Card",
        "imgSrc": Swiggy,
        "href": "https://indiasales.club/TBKH0476/HA2N2699/SH",
        "benefits": [
          "Lifetime Free for limited time",
          " 10% Cashback on Swiggy Spends* ",
          " 5% Cashback on Online spends* ",
          " 1% Cahback on all other spends ",
          " Free 3 month Swiggy One membership worth ₹1199* "
        ]
      }
  ]

  const OnlineSpends=[
    {
      "title": "AU Small Finance Bank Credit Card",
      "imgSrc": AU,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/AK",
      "benefits": [
        "No physical documents required",
        "25% discount on movie tickets*",
        "Lifetime Free Credit Cards (selected variants)",
        "Instant approval",
        "Complimentary Airport, Railway Lounge Access",
        "Discounts on Zomato, Swiggy and online shopping*"
      ]
    },
    // {
    //   "title": "HDFC Credit Card",
    //   "imgSrc": HDFC,
    //   "href": "https://indiasales.club/TBKH0476/HA2N2699/HD",
    //   "benefits": [
    //     "Exclusive reward points and cashbacks on online spends",
    //     "Welcome benefits",
    //     "Vouchers on monthly spends",
    //     "Complimentary Airport Lounge access",
    //     "Fuel surcharge waiver"
    //   ]
    // },
      // {
      //   "title": "Swiggy HDFC Bank Credit Card",
      //   "imgSrc": Swiggy,
      //   "href": "https://indiasales.club/TBKH0476/HA2N2699/SH",
      //   "benefits": [
      //     "Lifetime Free for limited time",
      //     " 10% Cashback on Swiggy Spends* ",
      //     " 5% Cashback on Online spends* ",
      //     " 1% Cahback on all other spends ",
      //     " Free 3 month Swiggy One membership worth ₹1199* "
      //   ]
      // },
      {
        "title": "IDFC FIRST Bank Credit Card",
        "imgSrc": Idfcfirst,
        "href": "https://indiasales.club/TBKH0476/HA2N2699/IF",
        "benefits": [
          "Lifetime Free Credit Card",
          " Welcome voucher worth ₹500 on spending ₹5000 within first 30 days ",
          " Reward Points 3X Rewards on Online and Offline spends 10X Rewards on monthly spends above ₹20,000 - 1X Rewards on insurance and utility transactions - No Expiry ",
          " Additional benefits - 25% Off at movie tickets once a month (via PayTM) - Free road side assistance worth ₹1499 - Up to 20% discount across 1500+ restaurants and merchants ",
          " Insurance protection: Personal Accident Cover, Lost Card Liability Cover "
        ]
      },
      // {
      //   "title": "CASHBACK SBI Credit_Card",
      //   "imgSrc": Cashbacksbi,
      //   "href": "https://indiasales.club/TBKH0476/HA2N2699/CZ",
      //   "benefits": [
      //     "5% Cashback on online spends",
      //     " 1% Cashback on offline spends",
      //     " Cashback auto-credit to SBI Card account ",
      //     " Reversal of Renewal Fee on annual spends of Rs.2 Lakh ",
      //     "1% fuel surcharge waiver"      
      //   ]
      // },
      {
        "title": "SimplyCLICK SBI Credit_Card",
        "imgSrc": Simplyclicksbi,
        "href": "https://indiasales.club/TBKH0476/HA2N2699/BK",
        "benefits": [
          "10X Reward Points* on Exclusive Online Partners",
          " Get Amazon.in Gift Card worth ₹500",
          " Earn Reward Points on online shopping ",
          " Annual Fee Reversal on spending ₹1 Lakh in a year ",
          "e-Vouchers worth ₹2,000 on yearly spends of ₹1 Lakh and ₹2 Lakh",
          "1% fuel surcharge waiver"      
        ]
      }
  ]

  const OfflineSpends=[
    // {
    //   "title": "ICICI Credit Card",
    //   "imgSrc": Icici,
    //   "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
    //   "benefits": [
    //     "Enjoy discounts on shopping, dining, movies, and more ",
    //     " Enjoy cash rewards and ICICI bank rewards offer ",
    //     " Get complimentary access to airport lounges ",
    //     " Discounts on fuel purchases and waiver of fuel surcharge ",
    //     " No joining and annual fee on selected variants "
    //   ]
    // },
    {
      "title": "IDFC FIRST HPCL Credit Card",
      "imgSrc": HPCL,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/FL",
      "benefits": [
        "Welcome benefits worth ₹1250 to ₹1500(Based on variant)",
        " UPI Rupay Credit Card",
        " Up to 30x rewards on Fuel and FASTag ",
        " 0 Annual Fee on spending >= ₹50K /1.50 Lacs in previous year (Based on variant)* "      
      ]
    },
    // {
    //   "title": "IndusInd Credit Card",
    //   "imgSrc": Induslandcredit,
    //   "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
    //   "benefits": [
    //     "Earn attractive reward points and cashback. ",
    //     " Reward points that never expire - redeem anytime you want ",
    //     " Indus Easy Credit Card - lifetime free card ",
    //     " Enjoy complimentary airport lounge access ",
    //     " Fuel surcharge on all petrol pumps across India "
    //   ]
    // },
      {
        "title": "IDFC FIRST Bank Credit Card",
        "imgSrc": Idfcfirst,
        "href": "https://indiasales.club/TBKH0476/HA2N2699/IF",
        "benefits": [
          "Lifetime Free Credit Card",
          " Welcome voucher worth ₹500 on spending ₹5000 within first 30 days ",
          " Reward Points 3X Rewards on Online and Offline spends 10X Rewards on monthly spends above ₹20,000 - 1X Rewards on insurance and utility transactions - No Expiry ",
          " Additional benefits - 25% Off at movie tickets once a month (via PayTM) - Free road side assistance worth ₹1499 - Up to 20% discount across 1500+ restaurants and merchants ",
          " Insurance protection: Personal Accident Cover, Lost Card Liability Cover "
        ]
      },
      {
        "title": "CASHBACK SBI Credit_Card",
        "imgSrc": Cashbacksbi,
        "href": "https://indiasales.club/TBKH0476/HA2N2699/CZ",
        "benefits": [
          "5% Cashback on online spends",
          " 1% Cashback on offline spends",
          " Cashback auto-credit to SBI Card account ",
          " Reversal of Renewal Fee on annual spends of Rs.2 Lakh ",
          "1% fuel surcharge waiver"      
        ]
      },
      // {
      //   "title": "SimplySAVE SBI Credit_Card",
      //   "imgSrc": Simplysavesbi,
      //   "href": "https://indiasales.club/TBKH0476/HA2N2699/VS",
      //   "benefits": [
      //     "10X Rewards on Movies, Dining & Grocery Spends!",
      //     " On Other Spends, Earn 1 Reward Point per ₹150 Spent",
      //     " 2000 Reward Points on Spending ₹2000 in First 60 Days ",
      //     " Annual Fee Reversal on Spending ₹1 Lakh in a Year ",
      //     "1% fuel surcharge waiver"      
      //   ]
      // }
  ]

  const HighRewards=[
    {
      "title": "Axis Saving Account",
      "imgSrc": axisprimarycard,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      "benefits": [
        "10% cashback on Flipkart, Amazon & 20% cashback on Zomato and Tata 1 mg",
        "  No need to visit the Branch - Customer can avail 250+ online services ",
        " Get accidental insurance cover of up to ₹1 crore  ",
        "  Instant Virtual Debit card with 1% cashback on online spends  ",
        "  Instant Account opening with zero paperwork  ",
        "Instant Virtual Debit card with 1% cashback on online spends "
      ]
    },
    {
      "title": "Axis Amaze Savings Account",
      "imgSrc": axisamazecard,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      "benefits": [
        " Zero Balance Account @ ₹200/p.m.",
        "  Welcome Benefits worth ₹5999 on Amazon Prime, Swiggy, BookMyShow & Uber ",
        " No Charges on all bank ATM withdrawals, free Debit Card, Chequebook & more ",
        " No Charges on any Domestic Banking Transactions ",
        "2000 Edge Rewards points on spending ₹20,000/month"      
      ]
    },
    {
      "title": "IndusInd savings account ",
      "imgSrc": induslnddebit,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      "benefits": [
        "5% cashback* on fuel, entertainment, dining and OTT spends with the Delights debit card  ",
        "  5% cashback on Amazon* with the IndusInd Bank debit card ",
        " Zero balance requirement subject to Initial funding  ",
        "  20% off on Swiggy & 10% off on Bigbasket with the Delights debit card  ",
        " Buy 1 Get 1 on movie tickets on BookMyShow with the Delights debit card  "
      ]
    },
      {
        "title": "Indus Delite Savings Account",
        "imgSrc": indusdelight,
        "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
        "benefits": [
          "5% cashback* on fuel, entertainment, dining and OTT spends with the Delights debit card ",
          " 5% cashback on Amazon* with the IndusInd Bank debit card  ",
          " Zero balance requirement subject to Initial funding  ",
          "  Buy 1 Get 1 on movie tickets on BookMyShow with the Delights debit card "
        ]
      }
  ]

  const HighInterest=[
    {
      "title": "IDFC FIRST Bank Saving Account",
      "imgSrc": idfccard,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      "benefits": [
        " ZERO CHARGES on all services",
        "  Get up to 7.25% p.a. Interest on savings account  ",
        " Monthly interest credit on savings account  ",
        "  Free and unlimited ATM withdrawals   ",
        "  Free airport lounge access with Visa Platinum Debit Card  ",
        "15% Off on Zomato, 10% Off on Myntra and many more offers* "
      ]
    },
    {
      "title": "INDIE by IndusInd Bank",
      "imgSrc": indiecard,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      "benefits": [
        " Interest rate of up to 6.75% p.a.",
        "   Zero-balance savings account  ",
        " 3% rewards for Fuel spend  ",
        "  0 Forex Markup ",
        "1+1 Bookmyshow tickets with VISA Signature debit card. "      
      ]
    },
    {
      "title": "Airtel Payments Bank ",
      "imgSrc": airtelcard,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      "benefits": [
        " Cashless payments  ",
        "  Earn 7%* interest on savings  ",
        " 24X7 payment service  ",
        " Unlock ₹15,000+ discounts on 70+ brands with a Debit Card.   "
      ]
    }
  ]

  const Tide=[
    {
      "title": "Tide Business Account",
      "imgSrc": tide,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      "benefits": [
        " Quick online account opening",
        "   Manage & schedule payments   ",
        " Generate GST compliant invoices for free   ",
        "       Easy expense management    ",
        "       Directly manage debits on the app  ",
        "Use referral code: TBA123  "
      ]
    }
  ]

  const ANQ=[
    {
      "title": "ANQ Prepaid Card",
      "imgSrc": anq,
      "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
      "benefits": [
        " 5% Cashback on online spends",
        "   1% Cashback on offline spends   ",
        " Cashback auto-credit to SBI Card account   ",
        "       Reversal of Renewal Fee on annual spends of Rs.2 Lakh   ",
        "       1% fuel surcharge waiver  "
      ]
    }
  ]

  export{BusinessCC,RegularCC,ExtraCC,OnlineSpends,OfflineSpends,HighRewards,HighInterest,Tide,ANQ}