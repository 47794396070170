import React from 'react';
import './Whatsapp.css';

const Whatsapp = () => {
    return (
        <>
            <div id="whatsapp-widget">
                <a href="https://wa.me/919550581859" target="_blank">
                    <img src="https://cdn.pixabay.com/photo/2015/08/03/13/58/whatsapp-873316_1280.png"
                        alt="Chat with us on WhatsApp" />
                </a>
            </div>
        </>
    )
}
export default Whatsapp;