import bg from './images/bg.png';
import firstimage from './images/overview1.png';
import secondimage from './images/overview2.png';
import thirdimage from './images/overview3.png';
import Footer from './Footer.js';
import Whatsapp from './whatsapp.js';
import './About.css';


const About = () => {
    return (
        <div style={{ backgroundImage: `url(${bg})`,paddingTop:"70px" }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <p className='heading'><u>Company Overview</u></p>
                    </div>
                    <div className='container mx-auto'>
                        <div className="row aboutusrow">
                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                <p className='firstsubheading'>At SelectCards, we understand that finding the perfect card to match your spending habits and financial goals can be a daunting task. That’s why we simplify the process by leveraging advanced AI technology to analyze your profile and recommend the best credit, debit, and prepaid cards tailored to your unique needs.</p>
                            </div>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center'>
                                <img src={firstimage} className='logosize1' />
                            </div>
                        </div>
                        <div className='row aboutusrow'>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center'>
                                <img src={secondimage} className='logosize2' />
                            </div>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                <p className='firstsubheading'>Our mission is to empower individuals to make smarter financial choices by leveraging AI-driven insights to deliver personalized card recommendations. At SelectCards, we are committed to helping you optimize your wealth through tailored solutions that unlock cashback, rewards, exclusive offers, and complimentary services aligned with your unique lifestyle.</p>
                            </div>
                        </div>
                        <div className="row aboutusrow">
                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                <p className='firstsubheading'>At SelectCards, our vision is to empower every individual in India to make the right financial decisions that align with their personal goals and aspirations. We believe that informed, smart financial choices are the foundation of financial stability, growth, and long-term success.</p>
                            </div>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center'>
                                <img src={thirdimage} className='logosize3' />
                            </div>
                        </div>
                        <div className="row aboutusrow">
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                                <p className='firstsubheading'>In a rapidly evolving financial landscape, we aim to equip people with the tools, knowledge, and confidence needed to navigate complex decisions effectively. By leveraging cutting-edge technology and AI-driven insights, we guide individuals toward the most suitable financial solutions tailored to their unique needs.  </p>

                                <p className='firstsubheading'>Our vision is not just about offering financial products; it's about offering personalized solutions that enhance financial well-being, enabling every individual to achieve a prosperous, secure, and stress-free financial life.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* whatsapp widget */}
            <Whatsapp />
            {/* whatsapp widget */}
        </div>
    )
}

export default About;