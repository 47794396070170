import React,{useState} from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import yesbank from './images/card/Yesbank/yesbankdualcards.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';


function Yesbank() {
  const [isExpanded, setIsExpanded] = useState(false);

  const fullText = `Take a look at some of the most popular and rewarding Yes Bank Credit Cards available. We’ve gathered all the key features, benefits, and fees of these cards in one place, making it easier for you to explore, compare, and choose the one that best suits your needs. Whether you’re interested in earning rewards, enjoying travel perks, or seeking cashback, our guide has everything you need to make an informed decision. Read on to find the perfect Credit Card for your lifestyle and apply with confidence!`;

  const truncatedText = fullText.slice(0, Math.floor(fullText.length / 2)); // Display half of the text
  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi" }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <p className="mt-3 text-justify text-white ps-lg-4 pe-lg-4 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
              {isExpanded ? fullText : `${truncatedText}...`}
            </p>
            <div className="text-center">
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                style={{
                  backgroundColor: "#1976D3",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  padding: "5px 15px",
                  cursor: "pointer",
                }}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
          <div className="col-12 mt-4 p-3">
            <Card style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}><CardContent>

              <div className="d-lg-flex justify-content-between">
                <div>
                  <div className="text-center">
                    <img src={yesbank} className="img-fluid" style={{ height: "auto" }} title="Yes Bank Card" />
                  </div>
                  <h6 gutterBottom className="pt-3 fw-bold text-center" component="div">
                    Yes Bank ANQ Dual Credit Card
                  </h6>
                  <div className="p-2 mt-3" style={{ color: "text.secondary", fontSize: "14px" }}>
                    <p className="fw-bold">
                      Product Benefits:
                    </p>
                    <p>Rupay Credit Card:</p>
                    <p variant="body2">
                      <ArrowRightIcon />   Rupay credit card for UPI, rewards beyond rational
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  With Pi credit card, you can now scan & pay on your all favourite UPI apps like Google Pay, PhonePe with your credit limit

                    </p>
                    <p>Master Credit Card:</p>
                    <p variant="body2">
                      <ArrowRightIcon /> 24RP on every ₹200 spent on your frequent home needs
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Take your Phi credit card out for all your household expenses and earn 24 reward points on Groceries, supermarkets, department stores and more
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />  Get 24 reward points when dining out or ordering in with your family or loved ones
                    </p>
                    <p variant="body2">
                      <ArrowRightIcon />   Domestic and international lounge access
                    </p>
                  </div>
                </div>
                <div>
                  <a href="https://indiasales.club/TBKH0476/0NQ28042/IK" style={{ textDecoration: "none" }} target="_blank"><Card className="ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}>Apply Now <LaunchIcon /></Card></a>
                </div>
              </div>
            </CardContent></Card>
          </div>

          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Yesbank;