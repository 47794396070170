import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Induslandplatinum from './images/card/indus bank/PlatinumCard.png';
import Induslandcredit from './images/card/indus bank/InduslandCreditcard.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import bg from './images/bg.png';


function Induslndbank() {
  const [isExpanded, setIsExpanded] = useState(false);

  const fullText = `            With countless credit card options available in the Indian market, finding the right one can often feel like a daunting task—especially when it comes to exploring the best credit cards from IndusInd Bank. Each card offers unique features and benefits, which can make the selection process both confusing and time-consuming. To make this easier for you, we’ve compiled a list of the top IndusInd Bank Credit Cards, complete with their standout features and benefits. Whether you’re seeking business credit cards, cashback credit cards, premium privileges, rewards on your spending, or travel perks, our detailed guide has everything you need. Read on to discover which IndusInd Credit Card best suits your lifestyle and financial needs!`;

  const truncatedText = fullText.slice(0, Math.floor(fullText.length / 2)); // Display half of the text
  return (
    <>
      <div className="container-fluid" style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
        <div className="row p-3">
          <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <p className="mt-3 text-justify ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2" style={{ fontSize: "15.47px" }}>
              {isExpanded ? fullText : `${truncatedText}...`}
            </p>
            <div className="text-center">
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                style={{
                  backgroundColor: "#1976D3",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  padding: "5px 15px",
                  cursor: "pointer",
                }}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            </div>
          </div>
          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <div className="d-lg-flex justify-content-between">
              <div className="text-center p-5">
                <img src={Induslandplatinum} className="img-fluid" style={{ height: "180px" }} title="IndusInd Platinum RuPay Credit Card" />
                <h6 gutterBottom className="pt-3 fw-bold" component="div">
                  IndusInd Platinum RuPay Credit Card
                </h6>
              </div>
              <div className="mt-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                <p>
                  Product Benefits:
                </p>
                <p variant="body2">
                  <ArrowRightIcon />   Lifetime Free Credit Card
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />  UPI payments, now with Credit Card
                </p>
                <p variant="body2">
                  <ArrowRightIcon />  Earn 2 Reward Points for every ₹ 100 on non-UPI transactions
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />  Earn 1 Reward Point for every ₹ 100 on UPI transactions
                </p>
                <p variant="body2">
                  <ArrowRightIcon /> 1% Fuel Surcharge Waiver*
                </p>

              </div>
              <div>
                <a href="https://indiasales.club/TBKH0476/HA2N2699/DI" style={{ textDecoration: "none" }} target="_blank"><Card className=" ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>

              </div>
            </div>
          </div>

          <div className="col-12 mt-4 p-3" style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
            <div className="d-lg-flex justify-content-between">
              <div className="text-center p-5">
                <img src={Induslandcredit} style={{ height: "180px" }} className="img-fluid" title="IndusInd Credit Card" />
                <h6 gutterBottom className="pt-3 fw-bold" component="div">
                  IndusInd Credit Card
                </h6>
              </div>
              <div className="mt-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                <p>
                  Product Benefits:
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />    Earn attractive reward points and cashback.
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />    Reward points that never expire - redeem anytime you want
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />     Indus Easy Credit Card - lifetime free card
                </p>
                <p variant="body2" >
                  <ArrowRightIcon />  Enjoy complimentary airport lounge access
                </p>
                <p variant="body2">
                  <ArrowRightIcon />  Fuel surcharge on all petrol pumps across India
                </p>

              </div>
              <div>
                <a href="https://indiasales.club/TBKH0476/HA2N2699/IC" style={{ textDecoration: "none" }} target="_blank"><Card className="ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#0097FF" }}><span>Apply Now <LaunchIcon /></span> </Card></a>
              </div>
            </div>

          </div>

          <div className="col-12 p-5"></div>
        </div>
      </div>
    </>
  )
}

export default Induslndbank;