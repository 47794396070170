import React from "react";
import bg from './images/bg.png';
import { Button } from "@mui/material";
import { Airtelpayment,IdfcDebitCard,INDUSLNDDEBITCARD,AxisBankDebit } from "./Debitcards";
import Whatsapp from "./whatsapp";

const AllDebitcards=()=>{
    const [selectedBank, setSelectedBank] = React.useState("All");

    const renderBankComponent = () => {
        switch (selectedBank) {
            case "Airtel":
                return <Airtelpayment />;
            case "Idfc":
                return <IdfcDebitCard />;
            case "Induslnd":
                return <INDUSLNDDEBITCARD />;
            case "AXIS":
                return <AxisBankDebit />;
            default:
                return (<><Airtelpayment/>
                <IdfcDebitCard/>
                <INDUSLNDDEBITCARD/>
                <AxisBankDebit/>
                </>
                )
        }
    };
    return(
        <>
        <div className="container-fluid" style={{ fontFamily:"Satoshi",backgroundImage: `url(${bg})`,paddingTop:"70px" }}>
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="text-white mt-5"><u>Debit Cards</u></h1>
                    </div>
                    <div className="col-12">
                        <div className="container mx-auto">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-lg-flex justify-content-between mt-5">
                                        <Button variant="text" onClick={() => setSelectedBank("All")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "All" ? "#1976d2" : "",
                                                fontSize: selectedBank === "All" ? "12px" : "15px",
                                                borderRadius: "30px",
                                                border:selectedBank === "All" ? "" :"1px solid linear-gradient(to bottom, #E20C9B 0%, #4520CA 100%)",
                                            }}>
                                            All</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("Airtel")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "Airtel" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "Airtel" ? "12px" : "15px",
                                            }}>
                                            Airtel Payments</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("Idfc")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "Idfc" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "Idfc" ? "12px" : "15px",
                                            }}>
                                            IDFC</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("Induslnd")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "Induslnd" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "Induslnd" ? "12px" : "15px",
                                            }}>
                                            Induslnd</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("AXIS")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "AXIS" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "AXIS" ? "12px" : "15px",
                                            }}>
                                            AXIS</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-5">
                        {renderBankComponent()}
                    </div>
                </div>
            </div>
            {/* whatsapp widget */}
            <Whatsapp/>
            {/* whatsap widget */}
        </>
    )
}

export default AllDebitcards;