import React, { useState } from "react";
import bg from './images/bg.png';
import airtelcard from "./images/card/debitcards/airtel.png";
import axisprimarycard from './images/card/debitcards/axisprimary.png';
import axisamazecard from './images/card/debitcards/axis amaze.png';
import idfccard from './images/card/debitcards/idfc.png';
import indiecard from './images/card/debitcards/indie.png';
import indusdelight from './images/card/debitcards/indusdelight.png';
import induslnddebit from './images/card/debitcards/induslnddebit.png';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import { Card, CardContent } from "@mui/material";
import Button from '@mui/material/Button';

const Airtelpayment = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const fullText = `Airtel Payments Bank brings convenience to your fingertips with a seamless digital banking experience. Whether you're managing your savings or making payments, this service is designed to simplify your financial life. The account can be accessed 24/7, allowing you to perform transactions at any time. With Airtel Payments Bank, you no longer need to visit a physical branch. All services are available online, making banking faster and easier. The platform supports cashless payments, which means you can make secure payments directly from your phone. Open your account with Airtel Payments Bank today to start enjoying hassle-free banking. It is a perfect choice for individuals who want to manage their finances digitally. With instant access and reliable customer support, you are always in control. Get started now and experience banking like never before.`;

    const truncatedText = fullText.slice(0, Math.floor(fullText.length / 2));
    return (
        <>
            <div className="container-fluid" style={{ fontFamily: "Satoshi"}}>
                <div className="row p-3">
                    <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
                        borderRadius: "20px",
                        background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                        backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                        border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                        color: "white"
                    }}>
                        <p
                            className="mt-3 text-justify ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2"
                            style={{ fontSize: "15.47px" }}
                        >
                            {isExpanded ? fullText : `${truncatedText}...`}
                        </p>
                        <div className="text-center">
                            <Button
                                variant="contained"
                                onClick={() => setIsExpanded(!isExpanded)}
                                style={{
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px 15px",
                                    cursor: "pointer",
                                    textTransform: "none"
                                }}
                            >
                                {isExpanded ? "Read Less" : "Read More"}
                            </Button>
                        </div>
                    </div>
                    <div className="col-12 mt-4 p-3">
                        <Card sx={{
                            borderRadius: "20px",
                            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                            color: "white"
                        }}>
                            <CardContent>
                                <div className="d-lg-flex justify-content-between">
                                    <div className="text-center">
                                        <img src={airtelcard} className="img-fluid" style={{ height: "180px" }} title="Airtel Debit Card" />
                                        <h6 gutterBottom className="pt-3 fw-bold" component="div">
                                            Airtel Payments Bank Card
                                        </h6>
                                    </div>
                                    <div className="mt-2" style={{ color: "secondary", fontSize: "18px" }}>
                                        <p className="fw-bold">
                                            Product Benefits:
                                        </p>
                                        <p variant="body2" >
                                            <ArrowRightIcon />  Cashless payments
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />  Earn 7%* interest on savings
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon /> 24X7 payment service
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />  Unlock ₹15,000+ discounts on 70+ brands with a Debit Card.
                                        </p>

                                    </div>
                                    <div className="">
                                        <a href="https://indiasales.club/TBKH0476/HA2N2699/ZD" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976d2" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

const AxisBankDebit = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    
      const fullText = `Axis Bank Debit Card is your gateway to smarter, seamless, and secure transactions. Enjoy unparalleled convenience with access to your funds anytime, anywhere. Whether it's online shopping, dining out, or withdrawing cash, this card is accepted at millions of locations worldwide. Elevate your everyday expenses with exclusive offers and exciting rewards tailored to your lifestyle. Stay in control with advanced security features like OTP authentication and instant transaction alerts. Effortlessly track your spending and manage your account using Axis Bank's intuitive mobile and online banking platforms.`;
    
      const truncatedText = fullText.slice(0, Math.floor(fullText.length / 2));
    return (
        <>
            <div className="container-fluid" style={{ fontFamily: "Satoshi" }}>
                <div className="row p-3">
                    <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
                        borderRadius: "20px",
                        background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                        backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                        border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                        color: "white"
                    }}>
                        <p
                            className="mt-3 text-justify ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2"
                            style={{ fontSize: "15.47px" }}
                        >
                            {isExpanded ? fullText : `${truncatedText}...`}
                        </p>
                        <div className="text-center">
                            <Button
                                variant="contained"
                                onClick={() => setIsExpanded(!isExpanded)}
                                style={{
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px 15px",
                                    cursor: "pointer",
                                    textTransform: "none"
                                }}
                            >
                                {isExpanded ? "Read Less" : "Read More"}
                            </Button>
                        </div>
                    </div>
                    <div className="col-12 mt-4 p-3">
                        <Card sx={{
                            borderRadius: "20px",
                            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                            color: "white"
                        }}>
                            <CardContent>
                                <div className="d-lg-flex justify-content-between">
                                    <div className="text-center p-2">
                                        <img src={axisprimarycard} className="img-fluid" style={{ height: "180px" }} title="Axis Primary Debit Card" />
                                        <h6 gutterBottom className="pt-3 fw-bold" component="div">
                                            Axis Bank Primary Debit Card
                                        </h6>
                                    </div>
                                    <div className="mt-2 p-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                                        <p className="fw-bold">
                                            Product Benefits:
                                        </p>
                                        <p variant="body2" >
                                            <ArrowRightIcon />  10% cashback on Flipkart, Amazon & 20% cashback on Zomato and Tata 1 mg
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />  No need to visit the Branch - Customer can avail 250+ online services
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon /> Get accidental insurance cover of up to ₹1 crore
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />  Instant Virtual Debit card with 1% cashback on online spends
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   Instant Account opening with zero paperwork
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   Instant Virtual Debit card with 1% cashback on online spends
                                        </p>

                                    </div>
                                    <div className="p-2">
                                        <a href="https://indiasales.club/TBKH0476/HA2N2699/AX" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976d2" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    <div className="col-12 mt-4 p-3">
                        <Card sx={{
                            borderRadius: "20px",
                            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                            color: "white"
                        }}>
                            <CardContent>
                                <div className="d-lg-flex justify-content-between">
                                    <div className="text-center p-2">
                                        <img src={axisamazecard} className="img-fluid" style={{ height: "180px" }} title="Axis Primary Debit Card" />
                                        <h6 gutterBottom className="pt-3 fw-bold" component="div">
                                            Axis Bank Amaze Debit Card
                                        </h6>
                                    </div>
                                    <div className="mt-2 p-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                                        <p className="fw-bold">
                                            Product Benefits:
                                        </p>
                                        <p variant="body2" >
                                            <ArrowRightIcon />   Zero Balance Account @ ₹200/p.m.
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />  Welcome Benefits worth ₹5999 on Amazon Prime, Swiggy, BookMyShow & Uber
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon /> No Charges on all bank ATM withdrawals, free Debit Card, Chequebook & more
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />  No Charges on any Domestic Banking Transactions
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   2000 Edge Rewards points on spending ₹20,000/month
                                        </p>

                                    </div>
                                    <div className="p-2">
                                        <a href="https://indiasales.club/TBKH0476/HA2N2699/XZ" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976d2" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

const IdfcDebitCard = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    
      const fullText = `IDFC FIRST Bank Debit Cards provide a modern and efficient way to manage your finances. With zero charges on various services and the flexibility to access your funds anytime, these cards are an excellent option for individuals seeking a seamless banking experience. Enjoy exclusive benefits such as attractive cashback offers, discounts on popular platforms like Zomato and Myntra, and the convenience of free unlimited ATM withdrawals.
With IDFC FIRST Bank’s cutting-edge mobile banking app, you can easily track your spending, set transaction limits, and enjoy complete control over your card usage.
Apply for an IDFC FIRST Bank Debit Card today to experience unparalleled convenience, flexibility, and rewards.
`;
    
      const truncatedText = fullText.slice(0, Math.floor(fullText.length / 2));
    return (
        <>
            <div className="container-fluid" style={{ fontFamily: "Satoshi" }}>
                <div className="row p-3">
                    <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
                        borderRadius: "20px",
                        background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                        backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                        border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                        color: "white"
                    }}>
                        <p
                            className="mt-3 text-justify ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2"
                            style={{ fontSize: "15.47px" }}
                        >
                            {isExpanded ? fullText : `${truncatedText}...`}
                        </p>
                        <div className="text-center">
                            <Button
                                variant="contained"
                                onClick={() => setIsExpanded(!isExpanded)}
                                style={{
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px 15px",
                                    cursor: "pointer",
                                    textTransform: "none"
                                }}
                            >
                                {isExpanded ? "Read Less" : "Read More"}
                            </Button>
                        </div>
                    </div>
                    <div className="col-12 mt-4 p-3">
                        <Card sx={{
                            borderRadius: "20px",
                            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                            color: "white"
                        }}>
                            <CardContent>
                                <div className="d-lg-flex justify-content-between">
                                    <div className="text-center">
                                        <img src={idfccard} className="img-fluid" style={{ height: "180px" }} title="IDFC Debit Card" />
                                        <h6 gutterBottom className="pt-3 fw-bold" component="div">
                                            IDFC Bank Debit Card
                                        </h6>
                                    </div>
                                    <div className="mt-2" style={{ color: "text.secondary", fontSize: "18px" }}>
                                        <p className="fw-bold">
                                            Product Benefits:
                                        </p>
                                        <p variant="body2" >
                                            <ArrowRightIcon />  ZERO CHARGES on all services
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   Get up to 7.25% p.a. Interest on savings account
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon /> Monthly interest credit on savings account
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   Free and unlimited ATM withdrawals
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   Free airport lounge access with Visa Platinum Debit Card
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   15% Off on Zomato, 10% Off on Myntra and many more offers*
                                        </p>

                                    </div>
                                    <div className="">
                                        <a href="https://indiasales.club/TBKH0476/HA2N2699/IA" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976d2" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

const INDUSLNDDEBITCARD = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    
      const fullText = `Upgrade your spending experience with the IndusInd Debit Card, Enhance convenience and reward your lifestyle. Access your funds instantly and make secure transactions both online and offline. Whether you’re shopping, dining, or traveling, this card offers seamless payment options across the globe. With exciting cashback offers and exclusive discounts, every purchase becomes more rewarding. Stay secure with advanced features like OTP authentication and transaction alerts. Manage your spending with ease using the intuitive mobile banking app. Accepted at millions of locations worldwide, it ensures you never miss out on what you need. IndusInd Debit Card combines security, convenience, and rewards in one powerful card. Apply today and take the first step towards a smarter, more rewarding banking experience..`;
    
      const truncatedText = fullText.slice(0, Math.floor(fullText.length / 2));
    return (
        <>
            <div className="container-fluid" style={{ fontFamily: "Satoshi" }}>
                <div className="row p-3">
                    <div className="col-12 ps-5 pe-5 pt-4 pb-4" style={{
                        borderRadius: "20px",
                        background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                        backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                        border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                        color: "white"
                    }}>
                        <p
                            className="mt-3 text-justify ps-lg-5 pe-lg-5 ps-sm-2 pe-sm-2"
                            style={{ fontSize: "15.47px" }}
                        >
                            {isExpanded ? fullText : `${truncatedText}...`}
                        </p>
                        <div className="text-center">
                            <Button
                                variant="contained"
                                onClick={() => setIsExpanded(!isExpanded)}
                                style={{
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px 15px",
                                    cursor: "pointer",
                                    textTransform: "none"
                                }}
                            >
                                {isExpanded ? "Read Less" : "Read More"}
                            </Button>
                        </div>
                    </div>
                    <div className="col-12 mt-4 p-3">
                        <Card sx={{
                            borderRadius: "20px",
                            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                            color: "white"
                        }}>
                            <CardContent>
                                <div className="d-lg-flex justify-content-between">
                                    <div className="text-center p-2">
                                        <img src={indiecard} className="img-fluid" style={{ height: "180px" }} title="Indie Debit Card" />
                                        <h6 gutterBottom className="pt-3 fw-bold" component="div">
                                            Indie Debit Card
                                        </h6>
                                    </div>
                                    <div className="mt-2 p-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                                        <p className="fw-bold">
                                            Product Benefits:
                                        </p>
                                        <p variant="body2" >
                                            <ArrowRightIcon />  10% cashback on Flipkart, Amazon & 20% cashback on Zomato and Tata 1 mg
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />  No need to visit the Branch - Customer can avail 250+ online services
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon /> Get accidental insurance cover of up to ₹1 crore
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />  Instant Virtual Debit card with 1% cashback on online spends
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   Instant Account opening with zero paperwork
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   Instant Virtual Debit card with 1% cashback on online spends
                                        </p>

                                    </div>
                                    <div className="p-2">
                                        <a href="https://indiasales.club/TBKH0476/HA2N2699/AX" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976d2" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    <div className="col-12 mt-4 p-3">
                        <Card sx={{
                            borderRadius: "20px",
                            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                            color: "white"
                        }}>
                            <CardContent>
                                <div className="d-lg-flex justify-content-between">
                                    <div className="p-2">
                                        <img src={indusdelight} className="img-fluid" style={{ height: "auto" }} title="Induslnd Delight Debit Card" />
                                        <h6 gutterBottom className="pt-3 fw-bold" component="div">
                                            Induslnd Delight Debit Card
                                        </h6>
                                    </div>
                                    <div className="mt-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                                        <p className="fw-bold">
                                            Product Benefits:
                                        </p>
                                        <p variant="body2" >
                                            <ArrowRightIcon />    5% cashback* on fuel, entertainment, dining and OTT spends with the Delights debit card
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />    5% cashback on Amazon* with the IndusInd Bank debit card
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon /> Zero balance requirement subject to Initial funding
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   20% off on Swiggy & 10% off on Bigbasket with the Delights debit card
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />    Buy 1 Get 1 on movie tickets on BookMyShow with the Delights debit card
                                        </p>

                                    </div>
                                    <div className="">
                                        <a href="https://indiasales.club/TBKH0476/HA2N2699/ZV" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976d2" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    <div className="col-12 mt-4 p-3">
                        <Card sx={{
                            borderRadius: "20px",
                            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                            color: "white"
                        }}>
                            <CardContent>
                                <div className="d-lg-flex justify-content-between">
                                    <div className="p-2">
                                        <img src={induslnddebit} className="img-fluid" style={{ height: "180px" }} title="Induslnd Debit Card" />
                                        <h6 gutterBottom className="pt-3 fw-bold" component="div">
                                            Induslnd Debit Card
                                        </h6>
                                    </div>
                                    <div className="mt-2" style={{ color: "text.secondary", fontSize: "16px" }}>
                                        <p className="fw-bold">
                                            Product Benefits:
                                        </p>
                                        <p variant="body2" >
                                            <ArrowRightIcon />    5% cashback* on fuel, entertainment, dining and OTT spends with the Delights debit card
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />    5% cashback on Amazon* with the IndusInd Bank debit card
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon /> Zero balance requirement subject to Initial funding
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />   20% off on Swiggy & 10% off on Bigbasket with the Delights debit card
                                        </p>
                                        <p variant="body2">
                                            <ArrowRightIcon />    Buy 1 Get 1 on movie tickets on BookMyShow with the Delights debit card
                                        </p>

                                    </div>
                                    <div className="">
                                        <a href="https://indiasales.club/TBKH0476/HA2N2699/IS" style={{ textDecoration: "none" }} target="_blank"><div className="card ps-5 pe-5 pt-2 pb-2 text-white text-center fw-bold" style={{ backgroundColor: "#1976d2" }}><span>Apply Now <LaunchIcon /></span> </div></a>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Airtelpayment, AxisBankDebit, IdfcDebitCard, INDUSLNDDEBITCARD }; 