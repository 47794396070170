import React, { useState } from 'react';
import Slider from 'react-slick';
import bg from './images/bg.png';
import axiscard from './images/card/axis/1.png';
import AU from './images/card/AU/1.webp';
import HDFC from './images/card/hdfc/1.png';
import Shoppers from './images/card/hdfc/2.png';
import Tata from './images/card/hdfc/3.png';
import Swiggy from './images/card/hdfc/swiggy.png';
import HPCL from './images/card/idfc/HPCL.png';
import Swyp from './images/card/idfc/swyp.jpeg';
import Idfcfirst from './images/card/idfc/IDFCFIRST.webp';
import Induslandplatinum from './images/card/indus bank/PlatinumCard.png';
import Induslandcredit from './images/card/indus bank/InduslandCreditcard.png';
import Cashbacksbi from './images/card/sbi/cashback.png';
import Irctcsbi from './images/card/sbi/irctcsbi.png';
import Simplysavesbi from './images/card/sbi/simplysave.png';
import Simplyclicksbi from './images/card/sbi/simplyclick.png';
import Icici from './images/card/icici/icici.png';
import { Card, CardContent, Button } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Hdfcbank from './Hdfcbank';
import { withTheme } from '@emotion/react';

const CreditCardCarousel = () => {
  const [axisCardBenefits, setAxisCardBenefits] = useState(Array(10).fill(false)); // Assuming 10 cards

  const handleToggleBenefits = (index) => {
    const updatedBenefits = [...axisCardBenefits];
    updatedBenefits[index] = !updatedBenefits[index];
    setAxisCardBenefits(updatedBenefits);
  };

  const cards = [
    {
      title: "Axis Bank Credit Card",
      imgSrc: axiscard,
      href:"https://indiasales.club/TBKH0476/HA2N2699/ACK",
      benefits: [
        "Choose from multiple credit cards to suit your lifestyle",
        "Complimentary airport lounge access (on some variants)",
        "Exciting rewards and cashback benefits",
        "1% Fuel Surcharge Waiver",
        "Flipkart, Amazon, Zomato, PVR vouchers"
      ]
    },
    {
      title: "AU Small Finance Bank Credit Card",
      imgSrc: AU,
      href:"https://indiasales.club/TBKH0476/HA2N2699/AK",
      benefits: [
        "No physical documents required",
        "25% discount on movie tickets*",
        "Lifetime Free Credit Cards (selected variants)",
        "Instant approval",
        "Complimentary Airport, Railway Lounge Access",
        "Discounts on Zomato, Swiggy and online shopping*"
      ]
    },
    {
      title: "HDFC Shoppers Stop Credit Card",
      imgSrc: Shoppers,
      href:"https://indiasales.club/TBKH0476/HA2N2699/HC",
      benefits: [
        "₹500 Shoppers Stop voucher on spend of ₹3,000",
        "Complimentary Shoppers Stop membership",
        "UPI - Rupay Credit Card",
        "1% fuel surcharge waiver",
        "Earn 6 First Citizen Points for every ₹150 spent",
      ]
    },
    {
      title: "HDFC Credit Card",
      imgSrc: HDFC,
      href:"https://indiasales.club/TBKH0476/HA2N2699/HD",
      benefits: [
        "Exclusive reward points and cashbacks on online spends",
        "Welcome benefits",
        "Vouchers on monthly spends",
        "Complimentary Airport Lounge access",
        "Fuel surcharge waiver"
      ]
    },
    {
      title: "ICICI Credit Card",
      imgSrc: Icici,
      href:"https://indiasales.club/TBKH0476/HA2N2699/IK",
      benefits: [
        "Enjoy discounts on shopping, dining, movies, and more ",
        " Enjoy cash rewards and ICICI bank rewards offer ",
        " Get complimentary access to airport lounges ",
        " Discounts on fuel purchases and waiver of fuel surcharge ",
        " No joining and annual fee on selected variants "
      ]
    },
    {
      title: "IDFC FIRST Bank Credit Card",
      imgSrc: Idfcfirst,
      href:"https://indiasales.club/TBKH0476/HA2N2699/IF",
      benefits: [
        "Lifetime Free Credit Card",
        "Welcome voucher worth ₹500 on spending ₹5000 within first 30 days ",
        "Insurance protection: Personal Accident Cover, Lost Card Liability Cover "
      ]
    },
    {
      title: "IDFC FIRST SWYP Credit Card ",
      imgSrc: Swyp,
      href:"https://indiasales.club/TBKH0476/HA2N2699/PY",
      benefits: [
        " Discount coupon of ₹2,100 from EaseMyTrip",
        "Up to 20% discount on Domino’s, Zomato, TataCliq and many more",
        "2000 RPs on spending ₹5000 within first 30 days ",
        "1000 RPs on 1st EMI Transaction within first 30 days ",
        "25% discount on movie tickets on Paytm ",
        "Complimentary Railway Lounge Access "
      ]
    },
    {
      title: "IDFC FIRST HPCL Credit Card",
      imgSrc: HPCL,
      href:"https://indiasales.club/TBKH0476/HA2N2699/FL",
      benefits: [
        "Welcome benefits worth ₹1250 to ₹1500(Based on variant) ",
        "UPI Rupay Credit Card ",
        "Up to 30x rewards on Fuel and FASTag ",
        " 0 Annual Fee on spending >= ₹50K /1.50 Lacs in previous year (Based on variant)* "
      ]
    },
    // Add more cards as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default for larger screens
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024, // For screens larger than 1024px
        settings: {
          slidesToShow: 3, // Show 3 cards
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // For screens larger than 600px
        settings: {
          slidesToShow: 2, // Show 2 cards
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For screens smaller than 480px
        settings: {
          slidesToShow: 1, // Show 1 card
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
      <Slider {...settings}>
        {cards.map((card, index) => (
          <div key={index} className="col-12">
            <Card sx={{width:"275px"}} className="mt-3 mx-auto" style={{ borderRadius: "20px",
                    background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                    backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                    color: "white"}}>
              <CardContent>
              <div className='text-center' style={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                            src={card.imgSrc}
                            className="img-fluid"
                            style={{ height: "150px", objectFit: 'contain' }} // Ensure the image fits well
                            alt={card.title}
                        />
                    </div>
                <p gutterBottom className="pt-3 text-center" component="div" style={{ fontSize: "15px", color: "white" }}>
                  {card.title}
                </p>
                <div className="d-flex flex-md-row flex-column justify-content-between">
                  <Button variant="outlined" color="inherit" onClick={() => handleToggleBenefits(index)} sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>
                    {axisCardBenefits[index] ? "Hide Benefits" : "Show Benefits"}
                  </Button>
                  <a href={card.href} className="btn-container" style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                    <Button variant="contained" className="btn-responsive" sx={{ mt: 2, fontFamily: "Satoshi" }} style={{ textTransform: "none" }}>Apply Now</Button>
                  </a>
                </div>
                {axisCardBenefits[index] && (
                  <div>
                    <p style={{ fontWeight: 'Bold', marginTop: '10px' }}>
                      Product Benefits:
                    </p>
                    {card.benefits.map((benefit, idx) => (
                      <p key={idx} variant="body2" style={{ color: "white", fontSize: "12px" }}>
                        <ArrowRightIcon /> {benefit}
                      </p>
                    ))}
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
  );
};

export default CreditCardCarousel;