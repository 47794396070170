import React from "react";
import './App.css';
import Footer from "./Footer";
import CreditCardCarousel from "./Cardcarousel";
import { Link } from "react-router-dom";
import Cardcategory from "./Cardcategories";
// images
import AU from './images/card/AU/1.webp';
import Axis from './images/card/axis/1.png';
import HDFC from './images/card/hdfc/1.png';
import Shoppers from './images/card/hdfc/2.png';
import Tata from './images/card/hdfc/3.png';
import Swiggy from './images/card/hdfc/swiggy.png';
import HPCL from './images/card/idfc/HPCL.png';
import Swyp from './images/card/idfc/swyp.jpeg';
import Idfcfirst from './images/card/idfc/IDFCFIRST.webp';
import Induslandplatinum from './images/card/indus bank/PlatinumCard.png';
import Induslandcredit from './images/card/indus bank/InduslandCreditcard.png';
import Cashbacksbi from './images/card/sbi/cashback.png';
import Irctcsbi from './images/card/sbi/irctcsbi.png';
import Simplysavesbi from './images/card/sbi/simplysave.png';
import Simplyclicksbi from './images/card/sbi/simplyclick.png';
import Icici from './images/card/icici/icici.png';
import AI from './images/ICONS/AI POWERED.png';
import Artboard from './images/ICONS/Artboard.png';
import Comparision from './images/ICONS/COMPARISONS.png';
import Options from './images/ICONS/OPTIONS.png';
import bg from './images/bg.png';
// images
import Cards from "./images/cards.mp4";
import Text from "./images/text.mp4";
import Banner from './images/banner.mp4';
import Bannermobile from './images/bannermobile.mp4';
import Bannertext from './images/text.mp4';
import Bannercard from './images/cards.mp4';
import { Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Whatsapp from "./whatsapp";


function App() {

  const [showBenefits, setShowBenefits] = React.useState(false);
  const [axiscardbenefits, setAxiscardBenefits] = React.useState(false);
  const [hdfcstoppersbenefits, setHdfcstoppersbenefits] = React.useState(false);
  const [hdfccreditbenefits, sethdfccreditBenefits] = React.useState(false);
  const [cardstoggle, setCardstoggle] = React.useState(false);
  const [icicicreditbenefits, setIcicicreditbenefits] = React.useState(false);
  const [idfcbankcreditbenefits, setIdfcbankcreditbenefits] = React.useState(false);
  const [idfcfirstswypbenefits, setIdfcswpcardbenefits] = React.useState(false);
  const [idfcfirsthpclbenefits, setIdfcfirsthpclbenefits] = React.useState(false);
  const [induslandplatinumbenefits, setInduslandplatinumbenefits] = React.useState(false);
  const [induslandcreditcardbenefits, setInduslandcreditcardbenefits] = React.useState(false);
  const [cashbacksbibenefits, setCashbacksbibenefits] = React.useState(false);
  const [simplyclicksbibenefits, setSimplyclicksbibenefits] = React.useState(false);
  const [irctcsbiplatinumbenefits, setIrctcsbiplatinumbenefits] = React.useState(false);
  const [simplysavesbibenefits, setSimplysavesbibenefits] = React.useState(false);
  const [swiggyhdfcbenefits, setSwiggyhdfcbenefits] = React.useState(false);
  const [tataneuhdfcbenefits, setTataneuhdfcbenefits] = React.useState(false);

  const Irctcplatinum = () => {
    setIrctcsbiplatinumbenefits((prev) => !prev);
  }

  const SimplysaveSbi = () => {
    setSimplysavesbibenefits((prev) => !prev);
  }

  const Swiggyhdfc = () => {
    setSwiggyhdfcbenefits((prev) => !prev);
  }

  const Tataneuhdfc = () => {
    setTataneuhdfcbenefits((prev) => !prev);
  }

  const Induslandplatinumcardbenefits = () => {
    setInduslandplatinumbenefits((prev) => !prev);
  }

  const InduslandcreditBenefits = () => {
    setInduslandcreditcardbenefits((prev) => !prev);
  }

  const CashbacksbiBenefits = () => {
    setCashbacksbibenefits((prev) => !prev);
  }

  const SimplyclicksbiBenefits = () => {
    setSimplyclicksbibenefits((prev) => !prev);
  }

  const IcicicardBenefits = () => {
    setIcicicreditbenefits((prev) => !prev);
  }

  const Idfcbankcardbenefits = () => {
    setIdfcbankcreditbenefits((prev) => !prev);
  }

  const Idfcfirstswypcardbenefits = () => {
    setIdfcswpcardbenefits((prev) => !prev);
  }

  const Idfcfirsthpclcardbenefits = () => {
    setIdfcfirsthpclbenefits((prev) => !prev)
  }


  const toggleBenefits = () => {
    setShowBenefits((prev) => !prev);
  };

  const AxiscardBenefits = () => {
    setAxiscardBenefits((prev) => !prev);
  };

  const hdfcstoppersBenefits = () => {
    setHdfcstoppersbenefits((prev) => !prev)
  }

  const HDFCcreditbenefits = () => {
    sethdfccreditBenefits((prev) => !prev)
  }

  const Remainingcardstoggle = () => {
    setCardstoggle((prev) => !prev)
  }


  return (
    <div style={{ backgroundImage: `url(${bg})` }}>
      {/* Banner */}
      <div className="container-fluid">
        <div className="row banner-row">
          <div className="col-12" style={{paddingTop:"80px"}}>
            <video
            className="banner-card"
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: "100%", // Makes the video take full width
                height: "auto", // Maintains aspect ratio
              }}
            >
              <source src={Bannermobile} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="row secondrowbanner">
          <div className="col-12">
          <video
              style={{ width: "100%", objectFit: "contain" }}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={Banner} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      {/* Banner */}

      {/* Heading */}
      <div className="container-fluid" style={{ fontFamily: "Satoshi" }}>
        <div className="row p-lg-5">
          <div className="col-12">
            <p className="selectcardstext">At SelectCards, we aim to revolutionize financial decision-making in India. Our AI-driven platform provides customized recommendations for debit, credit, and prepaid cards that match your lifestyle. Whether you want to save more, earn rewards, or enjoy convenience, we ensure you choose the perfect card for your needs.</p>
          </div>
        </div>
      </div>
      {/* Heading */}

      {/* submitform */}
      <div className="container-fluid">
        <div className="container" style={{ fontFamily: "Satoshi" }}>
          <div className="row rowstyle">
            <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
              <p className="formtext">To get you the right card recommendations</p>
            </div>
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <Link to='/submit'><Button className="buttonstyle" variant="contained" onClick={(e) => e.stopPropagation()}>Click Here</Button></Link>
            </div>
          </div>
        </div>
      </div>
      {/* submitform */}


      {/* Card Categories */}
      <div className="container-fluid" style={{ fontFamily: "Satoshi" }}>
        <div className="row p-5">
          <div className="col-12 pb-5" id="category">
            <h3 className="cardcategorytext">Credit Card Collections</h3>
            <p className="cardheadingtext">Discover Cards that suits your lifestyle</p>
          </div>
          <CreditCardCarousel />
          <div className="col-12 text-center">
            <Button type="text" sx={{ textDecoration: "none", marginTop: "20px" }}><Link to='/creditcards' style={{ textDecoration: 'none', color: 'white' }}>Explore More <ArrowForwardIcon sx={{ marginBottom: "3px" }} /> </Link></Button>
          </div>
        </div>
      </div>
      {/* Card Categories */}


      {/* services */}
      <div className="container-fluid" style={{ fontFamily: "Satoshi" }} >
        <div className="row whychooserow">
          <div className="col-12" id="about">
            <h3 className="whychoosetext">Why Choose Select Cards?</h3>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card className="cardstyle1">
              <CardContent>
                <div className="text-center">
                  <img src={Artboard} style={{ width: "auto", height: "123.56px" }} className="img-fluid rounded" />
                </div>
                <p className="text-center fw-bold mt-3" style={{ fontSize: "18px" }}>Expert Insights</p>
                <p className="text-justify" style={{ fontSize: "13px" }}>Our platform ensures you fully understand the benefits and features of each card, so there’s no guesswork involved.
                </p>
              </CardContent>
            </Card>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card className="cardstyle2">
              <CardContent>
                <div className="text-center">
                  <img src={AI} className="img-fluid" style={{ width: "auto", height: "123.56px" }} alt="AI icon" />
                </div>
                <p className="text-center fw-bold mt-3" style={{ fontSize: "18px" }}>AI-Powered Personalization</p>
                <p className="text-justify" style={{ fontSize: "13px" }}>Our smart AI analyzes your spending habits and goals to recommend cards perfectly tailored to your lifestyle. Smart AI delivers card recommendations uniquely tailored to your spending and goals.
                </p>
              </CardContent>
            </Card>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
            <Card className="cardstyle3">
              <CardContent>
                <div className="text-center">
                  <img src={Comparision} className="img-fluid rounded" style={{ width: "auto", height: "123.56px" }} alt="comparision" />
                </div>
                <p className="text-center mt-3 fw-bold" style={{ fontSize: "18px" }}>Clear & Transparent Comparisons</p>
                <p className="text-justify" style={{ fontSize: "13px" }}>We dissect rewards, fees, and essential details, giving you a clear understanding to make the best card choice
                </p>
              </CardContent>
            </Card>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3" >
            <Card className="cardstyle4">
              <CardContent>
                <div className="text-center">
                  <img src={Options} className="img-fluid rounded" style={{ width: "auto", height: "123.56px" }} alt="card" />
                </div>
                <p className="text-center mt-3 fw-bold" style={{ fontSize: "18px" }}>Comprehensive Card Options</p>
                <p className="text-justify" style={{ fontSize: "13px" }}>Whether it’s travel rewards, cashback, low-interest rates, or credit-building, we provide a wide variety of choices to suit any financial goal.
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      {/* services */}



      {/* Footer */}
      <Footer />
      {/* Footer */}
      {/* whatsapp widget */}
      <Whatsapp/>
      {/* whatsapp widget */}
    </div>
  )
}

export default App;