import { Button, Card, CardContent } from "@mui/material";
import Footer from "./Footer";
import React from "react";
import googleplay from './images/googleplay.png';
import appstore from './images/appstore.png';
import anqcard from "./images/card/anq/XCARD.png";
import tidecard from "./images/card/debitcards/tide.png";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import bg from './images/bg.png';
import Whatsapp from "./whatsapp";
import './Prepaidcard.css';

const Prepaidcard = () => {
    const [showBenefits, setShowBenefits] = React.useState(false);
    const [tidecardbenefits, setTidecardBenefits] = React.useState(false);
      const [isExpanded, setIsExpanded] = React.useState(false);
    
      const fullText = `Unlock a world of exclusive rewards and benefits with our personalized prepaid cards. Whether you're seeking cashback, travel rewards, or luxury perks, every transaction becomes more rewarding. Our easy-to-use card matching tool helps you compare and find the best prepaid cards tailored to your lifestyle.

        We simplify your prepaid card selection by offering personalized recommendations, ensuring you choose the perfect card for your financial goals. From lifestyle cards to gift prepaid cards designed for special occasions, our platform provides a seamless, hassle-free experience to help you make informed decisions.
        
        Looking for added convenience? We offer no fee prepaid cards that give you more value without any hidden charges. For those planning a trip, explore our selection of prepaid cards for travel, providing secure and easy access to funds on the go. Additionally, many of our cards offer prepaid cards with direct deposit, allowing for automatic and timely transfers.
        
        Our platform also features prepaid debit cards for everyday spending, as well as virtual prepaid cards for online shopping or secure transactions. With all these options, we ensure you find the ideal prepaid card that fits your needs and elevates your lifestyle.
        
        Explore the full range of prepaid card offers and let us guide you to the perfect card today, backed by exceptional customer support.
        
        `;
        const truncatedText = fullText.slice(0, Math.floor(fullText.length / 4));

    const toggleBenefits = () => {
        setShowBenefits((prev) => !prev);
    };
    const toggleTidecardBenefits = () => {
        setTidecardBenefits((prev) => !prev);
    };

    return (
        <div style={{ backgroundImage: `url(${bg})`,paddingTop:"70px" }}>
            <div className="container-fluid" style={{ fontFamily: "Satoshi", padding: "20px" }}>
                <div className="row">
                    <div className="col-12">
                        <h5 className="pagename"><u>Prepaid Cards</u></h5>
                        <h1 className="subheadingtext">Unique Cards with Special Offers.</h1>
                    </div>
                    <div className="col-12" style={{
                        borderRadius: "20px",
                        background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                        backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                        border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                        color: "white",
                        marginTop:"25px",
                        padding:"20px"
                    }}>
                        <p
                            className="prepaidtext"
                        >
                            {isExpanded ? fullText : `${truncatedText}...`}
                        </p>
                        <div className="text-center">
                            <Button
                            className="readmorebutton"
                                variant="contained"
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                {isExpanded ? "Read Less" : "Read More"}
                            </Button>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mt-4">
                        <Card sx={{
                            borderRadius: "20px",
                            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                            color: "white"
                        }}>
                            <CardContent>
                                <div className=" justify-content-around">
                                    <div className="d-flex">
                                        <div>
                                            <img src={anqcard} className="img-style" alt="anqprepaidcard" />
                                        </div>
                                        <div className="p-3">
                                            <div>
                                                <a href="https://anqapp.onelink.me/dqtk/coll5" className="">
                                                    <img src={googleplay} className="img-link" />
                                                </a>
                                            </div>
                                            <div className="mt-2">
                                                <a href="https://anqapp.onelink.me/dqtk/coll5" className="">
                                                    <img src={appstore} className="img-link" />
                                                </a>
                                            </div>
                                            <div className="mt-3">
                                                <Button variant="outlined" color="inherit" className="buttonstyle" onClick={toggleBenefits}>
                                                    {showBenefits ? "Hide Benefits" : "Show Benefits"}
                                                </Button>
                                            </div>
                                            {showBenefits && (
                                                <>
                                                    <div className="mt-3 benefits">


                                                        <p className="fw-bold">
                                                            Product Benefits:
                                                        </p>
                                                        <p variant="body2" >
                                                            <ArrowRightIcon />  5% Cashback on online spends
                                                        </p>
                                                        <p variant="body2">
                                                            <ArrowRightIcon />  1% Cashback on offline spends
                                                        </p>
                                                        <p variant="body2">
                                                            <ArrowRightIcon /> Cashback auto-credit to SBI Card account
                                                        </p>
                                                        <p variant="body2">
                                                            <ArrowRightIcon /> Reversal of Renewal Fee on annual spends of Rs.2 Lakh
                                                        </p>
                                                        <p variant="body2">
                                                            <ArrowRightIcon /> 1% fuel surcharge waiver
                                                        </p>

                                                    </div></>)}
                                        </div>
                                    </div>


                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mt-4">
                        <Card sx={{
                            borderRadius: "20px",
                            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
                            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
                            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
                            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
                            color: "white"
                        }}>
                            <CardContent>
                                <div className="">
                                    <div className="d-flex">
                                        <div>
                                            <img src={tidecard} alt="tideprepaid" className="img-style" />
                                        </div>
                                        <div className="p-3">
                                            <a href="https://anqapp.sng.link/Avakj/psj1/bq4s" className="">
                                                <img src={googleplay} className="img-link" />
                                            </a>
                                            <div className=" mt-3">
                                                <Button variant="outlined" color="inherit" className="buttonstyle" onClick={toggleTidecardBenefits}>
                                                    {tidecardbenefits ? "Hide Benefits" : "Show Benefits"}
                                                </Button>
                                            </div>
                                            {tidecardbenefits && (
                                                <div className="mt-3 benefits">
                                                    <p className="fw-bold">
                                                        Product Benefits:
                                                    </p>
                                                    <p variant="body2" >
                                                        <ArrowRightIcon />  5% Cashback on online spends
                                                    </p>
                                                    <p variant="body2">
                                                        <ArrowRightIcon />  1% Cashback on offline spends
                                                    </p>
                                                    <p variant="body2">
                                                        <ArrowRightIcon /> Cashback auto-credit to SBI Card account
                                                    </p>
                                                    <p variant="body2">
                                                        <ArrowRightIcon /> Reversal of Renewal Fee on annual spends of Rs.2 Lakh
                                                    </p>
                                                    <p variant="body2">
                                                        <ArrowRightIcon /> 1% fuel surcharge waiver
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    {/* <div className="text-center mt-3">
                                        <Button variant="outlined" color="inherit" className="buttonstyle" onClick={toggleTidecardBenefits}>
                                            {tidecardbenefits ? "Hide Benefits" : "Show Benefits"}
                                        </Button>
                                    </div> */}

                                    {/* <div className="mt-3">
                                        <div className="text-center">
                                            <a href="https://anqapp.sng.link/Avakj/psj1/bq4s" className="">
                                                <img src={googleplay} className="img-link" />
                                            </a>
                                        </div>
                                    </div> */}
                                </div>

                            </CardContent>
                        </Card>
                    </div>
                </div>

            </div>
            <Footer />
            {/* whatsapp widget */}
            <Whatsapp/>
            {/* whatsapp widget */}
        </div>
    )
}

export default Prepaidcard;