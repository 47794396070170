import { Card, CardActions, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Button from '@mui/material/Button';
import bg from './images/bg.png';
import Whatsapp from './whatsapp';

const Blogs = () => {
  const [blogPost, setBlogPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch blog post data from the backend
    const fetchBlogPost = async () => {
      try {
        const response = await fetch('https://selectcards.ai/getblog.php'); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch blog post');
        }
        const data = await response.json();
        setBlogPost(data);
        console.log(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={{fontFamily:"Satoshi", backgroundImage: `url(${bg})`}}>
    <div className='container-fluid'>
        <div className='row' style={{paddingTop:"120px"}}>
            <div className='col-12'>
                <h1 className='text-center text-white pb-3'><u>Blogs</u></h1>
            </div>
          {blogPost.map((post, index) => (
            <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pt-4' key={index}>
                <Card className='p-2 mb-2' style={{
            borderRadius: "20px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))", // Glass-like gradient
            backdropFilter: "blur(3px)", // Optional: Adds a blur effect to the background
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Adds a shadow for depth
            border: "1px solid rgba(255, 255, 255, 0.5)", // Optional: Light border
            color: "white"
          }}>
                    <CardContent>
              <img src={`data:image/jpeg;base64,${post.image}`} className='img-fluid' />
              <h1 className='text-center mt-3'><u>{post.title}</u></h1>
              {post.paragraphs.map((paragraph, paragraphIndex) => (
                <div key={paragraphIndex}>
                 <p dangerouslySetInnerHTML={{ __html: paragraph.text.substring(0, 100) + '...' }} />
                </div>
              ))}
              </CardContent>
              <div className='col-12 text-end'>
                <Button variant="text" color="light"  href={`/blog/${post.id}`}>Read More</Button>
              </div>
              </Card>
              
            </div>
          ))}
        
      </div>
      
      
    </div>
    {/* Footer */}
    <Footer/>
      {/* Footer */}
      {/* whatsapp widget */}
      <Whatsapp/>
      {/* whatsap widget */}
    </div>
  );
};

export default Blogs;