import howitworks from './images/howitworks.png';
import bg from './images/bg.png';
import Footer from './Footer';
import './Howitworks.css';
import Whatsapp from './whatsapp';
const Howitworks=()=>{
    return(
        <div style={{backgroundImage:`url(${bg})`,paddingTop:"70px"}}>
        <div className='container-fluid' >
            <div className='row'>
                <div className='col-12'>
                    <p className='pagename'>Aboutus</p>
                    <p className='heading'>How it works?</p>
                </div>
                <div className='col-12 p-5'>
                    <img src={howitworks} className='img-fluid' alt='how it works' />
                </div>
            </div>
        </div>
        <Footer/>
        {/* whatsapp widget */}
      <Whatsapp/>
      {/* whatsapp widget */}
        </div>
    )
}
export default Howitworks;