import React, { useState } from 'react';

const BlogPostForm = () => {
  const [title, setTitle] = useState('');
  const [paragraphs, setParagraphs] = useState([
    {
      text: '',
      backlinks: [
        {
          text: '',
          url: '',
        },
      ],
    },
  ]);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    const blogData = {
      title,
      paragraphs,
      image,
    };

    try {
      const response = await fetch('https://selectcards.ai/blogpost.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(blogData),
      });

      if (!response.ok) {
        throw new Error('Failed to create blog post');
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddParagraph = () => {
    setParagraphs((prevParagraphs) => [
      ...prevParagraphs,
      {
        text: '',
        backlinks: [
          {
            text: '',
            url: '',
          },
        ],
      },
    ]);
  };

  const handleRemoveParagraph = (index) => {
    setParagraphs((prevParagraphs) => prevParagraphs.filter((paragraph, i) => i !== index));
  };

  const handleUpdateParagraphText = (index, text) => {
    setParagraphs((prevParagraphs) =>
      prevParagraphs.map((paragraph, i) => (i === index ? { ...paragraph, text } : paragraph))
    );
  };

  const handleUpdateBacklinkText = (paragraphIndex, backlinkIndex, text) => {
    setParagraphs((prevParagraphs) =>
      prevParagraphs.map((paragraph, i) =>
        i === paragraphIndex
          ? {
              ...paragraph,
              backlinks: paragraph.backlinks.map((backlink, j) =>
                j === backlinkIndex ? { ...backlink, text } : backlink
              ),
            }
          : paragraph
      )
    );
  };

  const handleUpdateBacklinkUrl = (paragraphIndex, backlinkIndex, url) => {
    setParagraphs((prevParagraphs) =>
      prevParagraphs.map((paragraph, i) =>
        i === paragraphIndex
          ? {
              ...paragraph,
              backlinks: paragraph.backlinks.map((backlink, j) =>
                j === backlinkIndex ? { ...backlink, url } : backlink
              ),
            }
          : paragraph
      )
    );
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result.split(',')[1]; // Extract the base64 encoded string
      setImage(base64Image);
    };
    reader.readAsDataURL(file);
  };

  return (
    <form onSubmit={handleSubmit} style={{paddingTop:"200px"}}>
      <label>
        Title:
        <input type="text" value={title} onChange={(event) => setTitle(event.target.value)} />
      </label>
      <label>
        Image:
        <input type="file" onChange={handleImageChange} />
      </label>
      {paragraphs.map((paragraph, index) => (
        <div key={index}>
          <label>
            Paragraph {index + 1}:
            <textarea
              value={paragraph.text}
              onChange={(event) => handleUpdateParagraphText(index, event.target.value)}
            />
          </label>
          <label>
            Backlinks:
            {paragraph.backlinks.map((backlink, backlinkIndex) => (
              <div key={backlinkIndex}>
                <input
                  type="text"
                  value={backlink.text}
                  onChange={(event) => handleUpdateBacklinkText(index, backlinkIndex, event.target.value)}
                  placeholder="Backlink text"
                />
                <input
                  type="text"
                  value={backlink.url}
                  onChange={(event) => handleUpdateBacklinkUrl(index, backlinkIndex, event.target.value)}
                  placeholder="Backlink URL"
                />
              </div>
            ))}
          </label>
          <button type="button" onClick={() => handleRemoveParagraph(index)}>
            Remove Paragraph
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddParagraph}>
        Add Paragraph
      </button>
      <button type="submit" disabled={loading}>
        {loading ? 'Creating...' : 'Create Blog Post'}
      </button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </form>
  );
};

export default BlogPostForm;