import { Button } from "@mui/material";
import React, { useState } from "react";
import Sbibank from "./Sbibank";
import Axisbank from "./Axisbank";
import Hdfcbank from "./Hdfcbank";
import Icicibank from "./Icici";
import Idfcbank from "./Idfc";
import Induslndbank from "./Induslnd";
import Yesbank from "./yesbank";
import bg from './images/bg.png';
import Allcards from "./Allcards";
import Whatsapp from "./whatsapp";

const Creditcards = () => {
    const [selectedBank, setSelectedBank] = useState("All");

    const renderBankComponent = () => {
        switch (selectedBank) {
            case "SBI":
                return <Sbibank />;
            case "AXIS":
                return <Axisbank />;
            case "HDFC":
                return <Hdfcbank />;
            case "ICICI":
                return <Icicibank />;
            case "IDFC":
                return <Idfcbank />;
            case "INDUSLAND":
                return <Induslndbank />;
            case "YESBANK":
                return <Yesbank />;
            default:
                return <Allcards />
        }
    };

    return (
        <div style={{backgroundImage: `url(${bg})`}}>
            <div className="container-fluid" style={{ fontFamily: "Satoshi", paddingTop: "70px" }}>
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="text-white mt-5"><u>Credit Cards</u></h1>
                    </div>
                    <div className="col-12">
                        <div className="container mx-auto">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-lg-flex justify-content-between mt-5">
                                        <Button variant="text" onClick={() => setSelectedBank("All")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "All" ? "#1976d2" : "",
                                                fontSize: selectedBank === "All" ? "12px" : "15px",
                                                borderRadius: "30px",
                                                border: selectedBank === "All" ? "" : "1px solid linear-gradient(to bottom, #E20C9B 0%, #4520CA 100%)",
                                            }}>
                                            All</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("SBI")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "SBI" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "SBI" ? "12px" : "15px",
                                            }}>
                                            Sbi</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("AXIS")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "AXIS" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "AXIS" ? "12px" : "15px",
                                            }}>
                                            AXIS</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("HDFC")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "HDFC" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "HDFC" ? "12px" : "15px",
                                            }}>
                                            HDFC</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("ICICI")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "ICICI" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "ICICI" ? "12px" : "15px",
                                            }}>
                                            ICICI</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("IDFC")} sx={{
                                            color: "white",
                                            backgroundColor: selectedBank === "IDFC" ? "#1976d2" : "",
                                            borderRadius: "30px",
                                            fontSize: selectedBank === "IDFC" ? "12px" : "15px",
                                        }}>
                                            IDFC</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("INDUSLAND")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "INDUSLAND" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "INDUSLAND" ? "12px" : "15px",
                                            }}>
                                            INDUSLAND</Button>
                                        <Button variant="text" onClick={() => setSelectedBank("YESBANK")}
                                            sx={{
                                                color: "white",
                                                backgroundColor: selectedBank === "YESBANK" ? "#1976d2" : "",
                                                borderRadius: "30px",
                                                fontSize: selectedBank === "YESBANK" ? "12px" : "15px",
                                            }}>
                                            YESBANK</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-5">
                        {renderBankComponent()}
                    </div>
                </div>
                {/* whatsapp widget */}
                <Whatsapp />
                {/* whatsapp widget */}
            </div>
        </div>
    );
}

export default Creditcards;