import React from 'react';
import './submitform.css';
import Icici from './images/card/icici/icici.png';
import Hdfcbank from './Hdfcbank';
import Whatsapp from "./whatsapp";
import { light } from "@mui/material/styles/createPalette";
import Footer from './Footer';
import bg from './images/bg.png';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { BusinessCC, RegularCC, ExtraCC, HighInterest, HighRewards, OnlineSpends, OfflineSpends, Tide, ANQ } from './BusinessCC';
import { Card, CardContent, CardActions, Typography, TextField, Snackbar, FormControl, Select, MenuItem, Button, Alert, Checkbox, ToggleButton, ToggleButtonGroup, FormControlLabel, Modal, Box, IconButton, Slider } from '@mui/material';


const Submit = () => {
  const [employementstatus, setEmployementstatus] = React.useState("");
  const [Name, setName] = React.useState('');
  const [Email, setEmail] = React.useState('');
  const [Phoneno, setPhoneno] = React.useState('');
  const [pincode, setPincode] = React.useState('');
  const [Monthlyincome, setMonthlyincome] = React.useState('');
  const [Existingcards, setExistingcards] = React.useState('');
  const [Highestlimit, setHighestlimit] = React.useState('');
  const [city, setCity] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [snackbarmessage, setSnackbarmessage] = React.useState('');
  const [Referalcode, setReferalCode] = React.useState('');
  const [showFirstform, setShowfirstform] = React.useState(true);
  const [showSecondForm, setshowSecondform] = React.useState(false);
  const [showThirdForm, setShowThirdform] = React.useState(false);
  const [alignment, setAlignment] = React.useState('Yes');
  const [loans, setLoans] = React.useState('Yes');
  const [modal, setModal] = React.useState(false);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const[selectedCategories,setSelectedCategories]=React.useState([]);
  const [onlinepercent, setOnlinepercent] = React.useState(50);
  const [offlinepercent, setOfflinepercent] = React.useState(50);
  const [lifestyle, setLifestyle] = React.useState('');
  const [BusinessCredit, setBusinessCredit] = React.useState(false);
  const [RegularCredit, setRegularCredit] = React.useState(false);
  const [Extracredit, setExtracredit] = React.useState(false);
  const [Onlinespending, setOnlinespending] = React.useState(false);
  const [Offlinespending, setOfflinespending] = React.useState(false);
  const [Highrewardsdebit, setHighrewardsdebit] = React.useState(false);
  const [Highinterestdebit, setHighinterestdebit] = React.useState(false);
  const [Tideprepaid, setTideprepaid] = React.useState(false);
  const [anqprepaid, setAnqprepaid] = React.useState(false);
  const [showBenefits, setShowBenefits] = React.useState({});
  const [businessBenefits, setBusinessBenefits] = React.useState({});
  const [regularbenefits, setRegularBenefits] = React.useState({});
  const [onlinebenefits, setOnlineBenefits] = React.useState({});
  const [offlinebenefits, setOfflineBenefits] = React.useState({});
  const [rewardsbenefits, setRewardsBenefits] = React.useState({});
  const [interestbenefits, setInterestBenefits] = React.useState({});
  const [tidebenefits, setTidebenefits] = React.useState({});
  const [anqbenefits, setAnqbenefits] = React.useState({});


  const anqtoggleBenefits = (index) => {
    setAnqbenefits((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }


  const tidetoggleBenefits = (index) => {
    setTidebenefits((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }


  const interesttoggleBenefits = (index) => {
    setInterestBenefits((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }


  const rewardstoggleBenefits = (index) => {
    setRewardsBenefits((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }


  const offlinetoggleBenefits = (index) => {
    setOfflineBenefits((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }


  const onlinetoggleBenefits = (index) => {
    setOnlineBenefits((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }


  const regulartoggleBenefits = (index) => {
    setRegularBenefits((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }


  const businesstoggleBenefits = (index) => {
    setBusinessBenefits((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }


  const toggleBenefits = (index) => {
    setShowBenefits((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // const BusinessCC=[
  //   {
  //     "title": "Axis Bank Credit Card",
  //     "imgSrc": axiscard,
  //     "href": "https://indiasales.club/TBKH0476/HA2N2699/ACK",
  //     "benefits": [
  //       "Choose from multiple credit cards to suit your lifestyle",
  //       "Complimentary airport lounge access (on some variants)",
  //       "Exciting rewards and cashback benefits",
  //       "1% Fuel Surcharge Waiver",
  //       "Flipkart, Amazon, Zomato, PVR vouchers"
  //     ]
  //   },
  //   {
  //     "title": "AU Small Finance Bank Credit Card",
  //     "imgSrc": AU,
  //     "href": "https://indiasales.club/TBKH0476/HA2N2699/AK",
  //     "benefits": [
  //       "No physical documents required",
  //       "25% discount on movie tickets*",
  //       "Lifetime Free Credit Cards (selected variants)",
  //       "Instant approval",
  //       "Complimentary Airport, Railway Lounge Access",
  //       "Discounts on Zomato, Swiggy and online shopping*"
  //     ]
  //   },
  //   {
  //     "title": "HDFC Credit Card",
  //     "imgSrc": HDFC,
  //     "href": "https://indiasales.club/TBKH0476/HA2N2699/HD",
  //     "benefits": [
  //       "Exclusive reward points and cashbacks on online spends",
  //       "Welcome benefits",
  //       "Vouchers on monthly spends",
  //       "Complimentary Airport Lounge access",
  //       "Fuel surcharge waiver"
  //     ]
  //   },
  //   {
  //     "title": "ICICI Credit Card",
  //     "imgSrc": Icici,
  //     "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
  //     "benefits": [
  //       "Enjoy discounts on shopping, dining, movies, and more ",
  //       " Enjoy cash rewards and ICICI bank rewards offer ",
  //       " Get complimentary access to airport lounges ",
  //       " Discounts on fuel purchases and waiver of fuel surcharge ",
  //       " No joining and annual fee on selected variants "
  //     ]
  //   },
  //   {
  //       "title": "IndusInd Credit Card",
  //       "imgSrc": Induslandcredit,
  //       "href": "https://indiasales.club/TBKH0476/HA2N2699/IK",
  //       "benefits": [
  //         "Earn attractive reward points and cashback. ",
  //         " Reward points that never expire - redeem anytime you want ",
  //         " Indus Easy Credit Card - lifetime free card ",
  //         " Enjoy complimentary airport lounge access ",
  //         " Fuel surcharge on all petrol pumps across India "
  //       ]
  //     }
  // ]

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const loanchange = (event, newAlignment) => {
    setLoans(newAlignment);
  }


  const handleClose = () => {

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const Submit = async (e) => {
    e.preventDefault();
    const formData = {
      customername: Name || null,          // Customer name
      email: Email || null,                // Email address
      mobileno: Phoneno || null,           // Mobile number
      monthlyincome: Monthlyincome || null, // Monthly income (ensure this variable exists in your frontend)
      employmentstatus: employementstatus || null, // Employment status
      existingcards: Existingcards || null, // Existing cards (ensure this variable exists in your frontend)
      highestlimit: pincode || null,       // Highest limit (mapped to 'pincode' for now)
      city: city || null                   // City (ensure this variable exists in your frontend)
    };

    try {
      const response = await fetch('https://selectcards.ai/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert the form data to JSON
      });

      const crm = await fetch('https://a1c0725b-f054-493d-bf2a-ac9f5851bb2f.neodove.com/integration/custom/ccc9327b-160a-42d7-a6fe-7bcb0b44c007/leads', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok && crm.ok) {
        const result = await response.json();
        console.log(result);
        setshowSecondform(true);
        setShowfirstform(false);
      } else {
        console.error('Error:', response.statusText);
        setSnackbarmessage('Failed to submit form. Please try again.');
        setOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarmessage('An error occurred. Please check the console for details.');
      setOpen(true);
    }
    setshowSecondform(true);
    setShowfirstform(false);
  }

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    // Update the selected values
    if (selectedValues.includes(value)) {
      // If the value is already selected, remove it
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      // If the value is not selected, add it
      setSelectedValues([...selectedValues, value]);
    }
  };

  const handleCategoryboxChange = (event) => {
    const { value } = event.target;
  const updatedCategories = new Set(selectedCategories);

  if (updatedCategories.has(value)) {
    updatedCategories.delete(value); // Remove if already present
  } else {
    updatedCategories.add(value); // Add if not present
  }

  setSelectedCategories([...updatedCategories]);
  };

  const handleSubmitSecondForm = (e) => {
    e.preventDefault();
    setshowSecondform(false);
    setShowfirstform(false);
    setShowThirdform(true);
  }

  const secondprevious = () => {
    setshowSecondform(false);
    setShowfirstform(true);
    setShowThirdform(false);
  }

  const thirdprevious = () => {
    setshowSecondform(true);
    setShowfirstform(false);
    setShowThirdform(false);
  }


  const totalformsubmit = async (e) => {
    e.preventDefault();
    const formData = {
      customername: Name,
      email: Email,
      mobileno: Phoneno,
      pincode: pincode,
      referalcode: Referalcode,
      monthlyincome: Monthlyincome,
      employmentstatus: employementstatus,
      existingcards: Existingcards,
      highestlimit: Highestlimit,
      lookingfor: selectedValues,
      existingLoans: loans,
      onlinespendings: onlinepercent,
      offlinespendings: offlinepercent,
      lifestyle: selectedCategories

    };

    try {
      const response = await fetch('https://selectcards.ai/ansubmitform.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert the form data to JSON
      });

      if (response.status === 200) {
        const result = await response.json();
        setSnackbarmessage('Thankyou Our Representive will contact soon');
        setOpen(true);
        setName('');
        setEmail('');
        setPhoneno('');
        setPincode('');
        setReferalCode('');
        setEmployementstatus('');
        setHighestlimit('');
        setMonthlyincome('');
        setSelectedValues('');
        setLoans('');
        setOnlinepercent('');
        setOfflinepercent('');
        setSelectedCategories('');
        setModal(true);
        if (selectedValues.includes('Credit') && selectedValues.includes('Debit') && selectedValues.includes('Prepaid')) {
          if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
            setAnqprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
            setAnqprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
            setAnqprepaid(true);
          } else if (employementstatus === 'Self Employed/Business') {
            setBusinessCredit(true);
          } else if (employementstatus === 'Salaried') {
            setRegularCredit(true);
          }
        }
        else if (selectedValues.includes('Credit') && selectedValues.includes('Debit')) {
          if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOnlinespending(true);
            setHighrewardsdebit(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOfflinespending(true);
            setHighinterestdebit(true);
          } else if (employementstatus === 'Self Employed/Business') {
            setBusinessCredit(true);
          } else if (employementstatus === 'Salaried') {
            setRegularCredit(true);
          }
        }
        else if (selectedValues.includes('Credit') && selectedValues.includes('Prepaid')) {
          if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOnlinespending(true);
            setTideprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOfflinespending(true);
            setTideprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOnlinespending(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOfflinespending(true);
            setAnqprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOnlinespending(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOfflinespending(true);
            setAnqprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOnlinespending(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOfflinespending(true);
            setAnqprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOnlinespending(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOfflinespending(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOfflinespending(true);
            setAnqprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOnlinespending(true);
            setAnqprepaid(true);
          } else if (employementstatus === 'Self Employed/Business') {
            setBusinessCredit(true);
          } else if (employementstatus === 'Salaried') {
            setRegularCredit(true);
          }
        }
        else if (selectedValues.includes('Debit') && selectedValues.includes('Prepaid')) {
          if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000) {
            setHighrewardsdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000) {
            setHighinterestdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setHighrewardsdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setHighinterestdebit(true);
            setTideprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000) {
            setHighrewardsdebit(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000) {
            setHighinterestdebit(true);
            setAnqprepaid(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setHighrewardsdebit(true);
            setAnqprepaid(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setHighinterestdebit(true);
            setAnqprepaid(true);
          } else if (employementstatus === 'Self Employed/Business') {
            setBusinessCredit(true);
          } else if (employementstatus === 'Salaried') {
            setRegularCredit(true);
          }
        }
        else if (selectedValues.includes('Credit')) {
          if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOnlinespending(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOfflinespending(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOfflinespending(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOnlinepercent(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOfflinespending(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Self Employed/Business' && Monthlyincome < 35000) {
            setRegularCredit(true);
            setOfflinespending(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOnlinespending(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome >= 35000 && Monthlyincome <= 45000) {
            setRegularCredit(true);
            setOfflinespending(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOnlinespending(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome > 45000) {
            setExtracredit(true);
            setOfflinespending(true);
          } else if (onlinepercent <= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOfflinespending(true);
          } else if (onlinepercent >= offlinepercent && employementstatus === 'Salaried' && Monthlyincome < 35000) {
            setBusinessCredit(true);
            setOnlinespending(true);
          } else if (employementstatus === 'Business') {
            setBusinessCredit(true);
          } else if (employementstatus === 'Salaried') {
            setRegularCredit(true);
          }
        } else if (selectedValues.includes('Debit')) {
          if (onlinepercent >= offlinepercent && Monthlyincome >= 35000) {
            setHighrewardsdebit(true);
          } else if (onlinepercent <= offlinepercent && Monthlyincome >= 35000) {
            setHighinterestdebit(true);
          } else if (onlinepercent >= offlinepercent && Monthlyincome < 35000) {
            setHighrewardsdebit(true);
          } else if (onlinepercent <= offlinepercent && Monthlyincome < 35000) {
            setHighinterestdebit(true);
          }
        } else if (selectedValues.includes('Prepaid')) {
          if (employementstatus === 'Self Employed/Business') {
            setTideprepaid(true);
          } else if (employementstatus === 'Salaried') {
            setAnqprepaid(true);
          }
        } else {
          setBusinessCredit(false);
          setRegularCredit(true);
          setHighinterestdebit(true);
          setHighrewardsdebit(true);
          setOnlinespending(true);
          setOfflinespending(true);
          setTideprepaid(true);
          setAnqprepaid(true);
        }
      } else {
        console.error('Error:', response.statusText);
        setSnackbarmessage('Failed to submit form. Please try again.');
        setOpen(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarmessage('An error occurred. Please check the console for details.');
      setOpen(true);
    }

  }
  const ModalhandleClose = () => {
    setModal(false);
  }
  const style = {
    fontFamily: 'Satoshi',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div id='#details' style={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={<IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ backgroundColor: 'white', color: 'green' }}>
          {snackbarmessage}
        </Alert>
      </Snackbar>
      {/* form to submit */}
      <div className="container-fluid" style={{ padding: "70px" }}>
        <div className="row">
          <div className="col-12">
            <p className="heading">To go further Submit your Details</p>
          </div>
          <div className="col-12 pt-5">
            <div className="container-fluid submitform">
              <div className="row">
                <div className="col-12">
                  <div>
                    {showFirstform && (
                      <>
                        <form onSubmit={Submit}>
                          {/* Responsive Flex Container */}
                          <Typography className="formtext">Name</Typography>
                          <TextField
                            className="my-text-field"
                            id="standard-basic"
                            type="text"
                            value={Name}
                            onChange={(e) => {
                              const regex = /^[A-Za-z\s]*$/; // Allows only letters and spaces
                              if (regex.test(e.target.value)) {
                                setName(e.target.value);
                              }
                            }}
                            inputProps={{
                              pattern: "[A-Za-z\\s]*", // Allows letters and spaces only
                            }}
                            variant="standard"
                            required
                          />

                          <br />
                          <Typography className="formtext">Email</Typography>
                          <TextField
                            className="my-text-field"
                            id="standard-basic"
                            type="email"
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="standard"
                            required
                          />
                          <br />
                          <Typography className="formtext">Phone Number</Typography>
                          <TextField
                            id="standard-basic"
                            type="text" // Use text to prevent browser-specific behavior for type="number"
                            className="my-text-field"
                            value={Phoneno}
                            onChange={(e) => {
                              const regex = /^\d{0,10}$/; // Matches only up to 10 digits
                              if (regex.test(e.target.value)) {
                                setPhoneno(e.target.value);
                              }
                            }}
                            inputProps={{
                              inputMode: "numeric", // Ensures numeric keyboard on mobile
                              pattern: "\\d{10}", // Accepts exactly 10 digits
                              maxLength: 10, // Limits the input length to 10 characters
                            }}
                            variant="standard"
                            required
                          />
                          <br />
                          <FormControl variant="standard" className="statusform">
                            <Typography className="formtext">Employment Status</Typography>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={employementstatus}
                              onChange={(e) => setEmployementstatus(e.target.value)}
                              sx={{
                                '& .MuiInputLabel-root': { color: 'white' }, // Label color
                                '& .MuiSelect-select': { color: 'white' }, // Text color
                                '& .MuiSvgIcon-root': { color: 'white' }, // Dropdown icon color

                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={"Salaried"}>Salaried</MenuItem>
                              {/* <MenuItem value={"Business"}>Self Employed</MenuItem> */}
                              <MenuItem value={"Self Employed/Business"}>Self Employed/Business</MenuItem>
                            </Select>
                          </FormControl>
                          <br />
                          <Typography className="formtext">Pincode</Typography>
                          <TextField
                            id="standard-basic"
                            type="text"
                            className="my-text-field"
                            value={pincode}
                            onChange={(e) => {
                              const regex = /^\d{0,6}$/; // Matches only up to 6 digits
                              if (regex.test(e.target.value)) {
                                setPincode(e.target.value);
                              }
                            }}
                            inputProps={{
                              inputMode: "numeric", // Ensures numeric keyboard on mobile
                              pattern: "\\d{6}", // Accepts exactly 6 digits
                              maxLength: 6, // Limits the input length to 6 characters
                            }}
                            variant="standard"
                            required
                          />
                          <br />
                          <Typography className="formtext">ReferalCode(optional)</Typography>
                          <TextField

                            id="standard-basic"
                            type="text"
                            value={Referalcode}
                            onChange={(e) => setReferalCode(e.target.value)}
                            variant="standard"
                            className="my-text-field"
                          />
                          <br />
                          <div>
                            <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}> {/* Flex container for checkbox and text */}
                              <FormControlLabel
                                control={<Checkbox sx={{ color: "white", fontSize: "10px" }} required />}
                                label="" // Remove the label from here
                                sx={{ marginRight: "3px" }} // Add some space between checkbox and text
                              />
                              <Typography className="conditioncheck"> {/* Ensure the text is styled correctly */}
                                I agree to terms and conditions
                              </Typography>
                            </div>
                          </div>
                          <div className="firstformnextbutton" >
                            <Button type="submit" variant="text" sx={{ color: "white", textTransform: "none" }} >
                              Next <ChevronRightIcon />
                            </Button>
                          </div>
                        </form>
                      </>
                    )}
                    {showSecondForm && (
                      <form onSubmit={handleSubmitSecondForm}>
                        {/* Second form fields can be added here */}
                        <Typography className="formtext">Monthly Income</Typography>
                        <TextField
                          className="my-text-field"
                          id="additional-info"
                          value={Monthlyincome}
                          onChange={(e) => setMonthlyincome(e.target.value)}
                          type="number"
                          variant="standard"
                          required
                        />
                        <br />
                        <Typography className="formtext mb-2">Existing Credit Cards*</Typography>
                        <div className="togglecontainer">
                          <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            className="togglebutton"
                          >
                            <ToggleButton
                              value="Yes"
                              sx={{
                                backgroundColor: alignment === "Yes" ? "white" : "gray", // White if selected, gray otherwise
                                "&:hover": {
                                  backgroundColor: "white", // White background on hover
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "white", // Ensure white background when selected
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "white", // Ensure white background on hover when selected
                                },
                              }}
                            >
                              YES
                            </ToggleButton>
                            <ToggleButton
                              value="No"
                              sx={{
                                backgroundColor: alignment === "No" ? "white" : "gray", // White if selected, gray otherwise
                                "&:hover": {
                                  backgroundColor: "white", // White background on hover
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "white", // Ensure white background when selected
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "white", // Ensure white background on hover when selected
                                },
                              }}
                            >
                              NO
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                        {alignment === "Yes" && (
                          <>
                            <br />
                            <Typography className="formtext">Highest Limit of Existing Credit Card*</Typography>
                            <TextField
                              className="my-text-field"
                              id="additional-info"
                              value={Highestlimit}
                              onChange={(e) => setHighestlimit(e.target.value)}
                              type="number"
                              variant="standard"
                              required
                            />
                          </>
                        )}

                        <br />
                        <Typography className="formtext mb-2">Existing Loans</Typography>
                        <div className="togglecontainer">
                          <ToggleButtonGroup
                            value={loans}
                            exclusive
                            onChange={loanchange}
                            aria-label="Platform"
                            className="togglebutton"
                          >
                            <ToggleButton
                              value="Yes"
                              sx={{
                                backgroundColor: loans === "Yes" ? "white" : "gray", // White if selected, gray otherwise
                                "&:hover": {
                                  backgroundColor: "white", // White background on hover
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "white", // Ensure white background when selected
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "white", // Ensure white background on hover when selected
                                },
                              }}
                            >
                              YES
                            </ToggleButton>
                            <ToggleButton
                              value="No"
                              sx={{
                                backgroundColor: loans === "No" ? "white" : "gray", // White if selected, gray otherwise
                                "&:hover": {
                                  backgroundColor: "white", // White background on hover
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "white", // Ensure white background when selected
                                },
                                "&.Mui-selected:hover": {
                                  backgroundColor: "white", // Ensure white background on hover when selected
                                },
                              }}
                            >
                              NO
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                        <br />
                        <Typography className="formtext">What are you looking for?</Typography>
                        <div className="d-flex justify-content-between">
                          <Checkbox
                            sx={{ color: "white" }}
                            value="Credit"
                            onChange={handleCheckboxChange}
                          />
                          <span className="checkboxtext">Credit</span>

                          <Checkbox
                            sx={{ color: "white" }}
                            value="Debit"
                            onChange={handleCheckboxChange}
                          />
                          <span className="checkboxtext">Debit</span>

                          <Checkbox
                            sx={{ color: "white" }}
                            value="Prepaid"
                            onChange={handleCheckboxChange}
                          />
                          <span className="checkboxtext">Prepaid</span>
                        </div>

                        <div className="d-flex justify-content-between">
                          <Button variant="text" className="nextbutton" onClick={secondprevious}><ChevronLeftIcon /> Previous </Button>
                          <Button type='submit' variant="text" className="nextbutton">
                            Next <ChevronRightIcon />
                          </Button>
                        </div>
                      </form>
                    )}
                    {showThirdForm && (
                      <>
                        <form>
                          <Typography className="formtext">How Much percent you spend online of your Monthly Income?</Typography>
                          <Slider value={onlinepercent} onChange={(e) => setOnlinepercent(e.target.value)} aria-label="Default" valueLabelDisplay="auto" />
                          <div className="d-flex justify-content-between">
                            <div>
                              <p>0%</p>
                            </div>
                            <div>
                              <p>100%</p>
                            </div>
                          </div>
                          <Typography className="formtext">How much percent you spend offline of your Monthly Income?</Typography>
                          <Slider value={offlinepercent} onChange={(e) => setOfflinepercent(e.target.value)} aria-label="Default" valueLabelDisplay="auto" />
                          <div className="d-flex justify-content-between">
                            <div>
                              <p>0%</p>
                            </div>
                            <div>
                              <p>100%</p>
                            </div>
                          </div>
                          <Typography className="formtext">On What do you Spend most?</Typography>
                          <div className="d-flex flex-column mt-2">
                            <div className="d-flex justify-content-between mb-2">
                              <div>
                              <Checkbox
                                sx={{ color: "white" }}
                                value="Travel & Leisure"
                                onChange={handleCategoryboxChange}
                              />
                              <span className="checkboxtext">Travel & Leisure</span>
                              </div>
                              <div>
                              <Checkbox
                                sx={{ color: "white" }}
                                value="Food"
                                onChange={handleCategoryboxChange}
                              />
                              <span className="checkboxtext">Food</span>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between mb-2">
                              <div>
                              <Checkbox
                                sx={{ color: "white" }}
                                value="Medical"
                                onChange={handleCategoryboxChange}
                              />
                              <span className="checkboxtext">Medical</span>
                              </div>
                              <div>
                              <Checkbox
                                sx={{ color: "white" }}
                                value="Shopping"
                                onChange={handleCategoryboxChange}
                              />
                              <span className="checkboxtext">Shopping</span>
                              </div>
                            </div>

                            <div className="d-flex  mb-2">
                              <Checkbox
                                sx={{ color: "white" }}
                                value="Bill Payments"
                                onChange={handleCategoryboxChange}
                              />
                              <span className="checkboxtext">Bill Payments</span>
                            </div>
                          </div>

                          <br />
                          <div className="d-flex justify-content-between">
                            <Button variant="text" className="nextbutton" onClick={thirdprevious}><ChevronLeftIcon /> Previous </Button>
                            <Button type="button" className="submitbutton" variant="contained" onClick={totalformsubmit}>Submit</Button>
                          </div>
                        </form>
                        <Modal
                          open={modal}
                          onClose={ModalhandleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="recommendedmodal pt-5">
                            <Typography id="modal-modal-title" className="modalheading" sx={{ fontFamily: "Azonix" }} variant="h6" component="h2">
                              Recommended Cards
                            </Typography>
                            <div className="container">
                              <div className="row">
                                {Extracredit && (
                                  <>
                                    <div className="col-12">
                                      <p className="text-dark text-center fw-bold"><u>Recommended Credit Cards</u></p>
                                    </div>
                                    {ExtraCC.map((cards, index) => (
                                      <>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                          <Card sx={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>

                                            <CardContent>
                                              <img src={cards.imgSrc} style={{ width: "100%", height: "auto" }} alt={cards.title} />
                                              <Typography className="recommendedcardheading">{cards.title}</Typography>
                                              <a href={cards.href}><Button variant="contained" className="recommendedcardbutton">Apply Now</Button></a>
                                              <Button
                                                variant="outlined"
                                                className="recommendedcardbutton ms-2"
                                                onClick={() => toggleBenefits(index)}
                                              >
                                                {showBenefits[index] ? "Hide Benefits" : "Show Benefits"}
                                              </Button>
                                              {showBenefits[index] && (
                                                <ul className='mt-2' style={{ paddingLeft: "0", marginLeft: "0", listStylePosition: "inside" }}>
                                                  {cards.benefits.map((benefit, i) => (
                                                    <li className='recommendedbenefits' key={i}>{benefit}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}
                                {BusinessCredit && (
                                  <>
                                    <div className="col-12">
                                      <p className="text-dark text-center fw-bold"><u>Recommended Credit Cards</u></p>
                                    </div>
                                    {BusinessCC.map((cards, index) => (
                                      <>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                          <Card sx={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>

                                            <CardContent>
                                              <img src={cards.imgSrc} style={{ width: "100%", height: "auto" }} alt={cards.title} />
                                              <Typography className="recommendedcardheading">{cards.title}</Typography>
                                              <a href={cards.href}><Button variant="contained" className="recommendedcardbutton">Apply Now</Button></a>
                                              <Button
                                                variant="outlined"
                                                className="recommendedcardbutton ms-2"
                                                onClick={() => businesstoggleBenefits(index)}
                                              >
                                                {businessBenefits[index] ? "Hide Benefits" : "Show Benefits"}
                                              </Button>
                                              {businessBenefits[index] && (
                                                <ul className='mt-2' style={{ paddingLeft: "0", marginLeft: "0", listStylePosition: "inside" }}>
                                                  {cards.benefits.map((benefit, i) => (
                                                    <li className='recommendedbenefits' key={i}>{benefit}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}

                                {RegularCredit && (
                                  <>
                                    <div className="col-12">
                                      <p className="text-dark text-center fw-bold"><u>Recommended Credit Cards</u></p>
                                    </div>
                                    {RegularCC.map((cards, index) => (
                                      <>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                          <Card sx={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>

                                            <CardContent>
                                              <img src={cards.imgSrc} style={{ width: "100%", height: "auto" }} alt={cards.title} />
                                              <Typography className="recommendedcardheading">{cards.title}</Typography>
                                              <a href={cards.href}><Button variant="contained" className="recommendedcardbutton">Apply Now</Button></a>
                                              <Button
                                                variant="outlined"
                                                className="recommendedcardbutton ms-2"
                                                onClick={() => regulartoggleBenefits(index)}

                                              >
                                                {regularbenefits[index] ? "Hide Benefits" : "Show Benefits"}
                                              </Button>
                                              {regularbenefits[index] && (
                                                <ul className='text-start mt-2' style={{ paddingLeft: "0", marginLeft: "0", listStylePosition: "inside" }}>
                                                  {cards.benefits.map((benefit, i) => (
                                                    <li className='recommendedbenefits' key={i}>{benefit}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}

                                {Onlinespending && (
                                  <>
                                    {OnlineSpends.map((cards, index) => (
                                      <>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                          <Card sx={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>

                                            <CardContent>
                                              <img src={cards.imgSrc} style={{ width: "100%", height: "auto" }} alt={cards.title} />
                                              <Typography className="recommendedcardheading">{cards.title}</Typography>
                                              <a href={cards.href}><Button variant="contained" className="recommendedcardbutton">Apply Now</Button></a>
                                              <Button
                                                variant="outlined"
                                                className="recommendedcardbutton ms-2"
                                                onClick={() => onlinetoggleBenefits(index)}
                                              >
                                                {onlinebenefits[index] ? "Hide Benefits" : "Show Benefits"}
                                              </Button>
                                              {onlinebenefits[index] && (
                                                <ul className='mt-2' style={{ paddingLeft: "0", marginLeft: "0", listStylePosition: "inside" }}>
                                                  {cards.benefits.map((benefit, i) => (
                                                    <li className='recommendedbenefits' key={i}>{benefit}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}

                                {Offlinespending && (
                                  <>
                                    {OfflineSpends.map((cards, index) => (
                                      <>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                          <Card sx={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>

                                            <CardContent>
                                              <img src={cards.imgSrc} style={{ width: "100%", height: "auto" }} alt={cards.title} />
                                              <Typography className="recommendedcardheading">{cards.title}</Typography>
                                              <a href={cards.href}><Button variant="contained" className="recommendedcardbutton">Apply Now</Button></a>
                                              <Button
                                                variant="outlined"
                                                className="recommendedcardbutton ms-2"
                                                onClick={() => offlinetoggleBenefits(index)}
                                              >
                                                {offlinebenefits[index] ? "Hide Benefits" : "Show Benefits"}
                                              </Button>
                                              {offlinebenefits[index] && (
                                                <ul className='mt-2' style={{ paddingLeft: "0", marginLeft: "0", listStylePosition: "inside" }}>
                                                  {cards.benefits.map((benefit, i) => (
                                                    <li className='recommendedbenefits' key={i}>{benefit}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </CardContent>

                                          </Card>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}
                              </div>
                              <div className="row mt-3">

                                {Highrewardsdebit && (
                                  <>
                                    <div className="col-12">
                                      <p className="text-dark text-center fw-bold"><u>Recommended Debit Cards</u></p>
                                    </div>
                                    {HighRewards.map((cards, index) => (
                                      <>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                          <Card sx={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>

                                            <CardContent>
                                              <img src={cards.imgSrc} style={{ width: "100%", height: "auto" }} alt={cards.title} />
                                              <Typography className="recommendedcardheading">{cards.title}</Typography>
                                              <a href={cards.href}><Button variant="contained" className="recommendedcardbutton">Apply Now</Button></a>
                                              <Button
                                                variant="outlined"
                                                className="recommendedcardbutton ms-2"
                                                onClick={() => rewardstoggleBenefits(index)}
                                              >
                                                {rewardsbenefits[index] ? "Hide Benefits" : "Show Benefits"}
                                              </Button>
                                              {rewardsbenefits[index] && (
                                                <ul className='mt-2' style={{ paddingLeft: "0", marginLeft: "0", listStylePosition: "inside" }}>
                                                  {cards.benefits.map((benefit, i) => (
                                                    <li className='recommendedbenefits' key={i}>{benefit}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}

                                {Highinterestdebit && (
                                  <>
                                    <div className="col-12">
                                      <p className="text-dark text-center fw-bold"><u>Recommended Debit Cards</u></p>
                                    </div>
                                    {HighInterest.map((cards, index) => (
                                      <>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                          <Card sx={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>

                                            <CardContent>
                                              <img src={cards.imgSrc} style={{ width: "100%", height: "auto" }} alt={cards.title} />
                                              <Typography className="recommendedcardheading">{cards.title}</Typography>
                                              <a href={cards.href}><Button variant="contained" className="recommendedcardbutton">Apply Now</Button></a>
                                              <Button
                                                variant="outlined"
                                                className="recommendedcardbutton ms-2"
                                                onClick={() => interesttoggleBenefits(index)}
                                              >
                                                {interestbenefits[index] ? "Hide Benefits" : "Show Benefits"}
                                              </Button>
                                              {interestbenefits[index] && (
                                                <ul className='mt-2' style={{ paddingLeft: "0", marginLeft: "0", listStylePosition: "inside" }}>
                                                  {cards.benefits.map((benefit, i) => (
                                                    <li className='recommendedbenefits' key={i}>{benefit}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}

                              </div>
                              <div className="row mt-3">

                                {Tideprepaid && (
                                  <>
                                    <div className="col-12">
                                      <p className="text-dark text-center fw-bold"><u>Recommended Prepaid Cards</u></p>
                                    </div>
                                    {Tide.map((cards, index) => (
                                      <>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                          <Card sx={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>

                                            <CardContent>
                                              <img src={cards.imgSrc} style={{ width: "100%", height: "auto" }} alt={cards.title} />
                                              <Typography className="recommendedcardheading">{cards.title}</Typography>
                                              <a href={cards.href}><Button variant="contained" className="recommendedcardbutton">Apply Now</Button></a>
                                              <Button
                                                variant="outlined"
                                                className="recommendedcardbutton ms-2"
                                                onClick={() => tidetoggleBenefits(index)}
                                              >
                                                {tidebenefits[index] ? "Hide Benefits" : "Show Benefits"}
                                              </Button>
                                              {tidebenefits[index] && (
                                                <ul className='mt-2' style={{ paddingLeft: "0", marginLeft: "0", listStylePosition: "inside" }}>
                                                  {cards.benefits.map((benefit, i) => (
                                                    <li className='recommendedbenefits' key={i}>{benefit}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}
                                {anqprepaid && (
                                  <>
                                    <div className="col-12">
                                      <p className="text-dark text-center fw-bold"><u>Recommended Prepaid Cards</u></p>
                                    </div>
                                    {ANQ.map((cards, index) => (
                                      <>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 mt-2">
                                          <Card sx={{ fontFamily: "Satoshi", backgroundImage: `url(${bg})` }}>

                                            <CardContent>
                                              <img src={cards.imgSrc} style={{ width: "100%", height: "auto" }} alt={cards.title} />
                                              <Typography className="recommendedcardheading">{cards.title}</Typography>
                                              <a href={cards.href}><Button variant="contained" className="recommendedcardbutton">Apply Now</Button></a>
                                              <Button
                                                variant="outlined"
                                                className="recommendedcardbutton ms-2"
                                                onClick={() => anqtoggleBenefits(index)}
                                              >
                                                {anqbenefits[index] ? "Hide Benefits" : "Show Benefits"}
                                              </Button>
                                              {anqbenefits[index] && (
                                                <ul className='mt-2' style={{ paddingLeft: "0", marginLeft: "0", listStylePosition: "inside" }}>
                                                  {cards.benefits.map((benefit, i) => (
                                                    <li className='recommendedbenefits' key={i}>{benefit}</li>
                                                  ))}
                                                </ul>
                                              )}
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                          </Box>
                        </Modal>
                      </>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* form to submit */}
      <Footer />
      {/* whatsapp widget */}
      <Whatsapp />
      {/* whatsapp widget */}
    </div>
  )
}
export default Submit;
