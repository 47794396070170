// src/AnalyticsChart.js

import React,{useState,useEffect} from 'react';
import { Bar,Pie,Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement,
} from 'chart.js';

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement,ArcElement,LineElement,PointElement, Title, Tooltip, Legend);

const AnalyticsChart = ({ data }) => {
    // Prepare the data for the chart
    const labels = data.rows.map(row => {
        // Format the date from YYYYMMDD to a more readable format
        const date = row.dimensionValues[0].value;
        return `${date.slice(6, 8)}-${date.slice(4, 6)}-${date.slice(0, 4)}`; // YYYY-MM-DD
    });

    const values = data.rows.map(row => parseInt(row.metricValues[0].value, 10));

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Active Users',
                data: values,
                backgroundColor: 'rgb(66,133,244)', // Change this color
                borderColor: 'rgb(66,133,244)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Users',
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Active Users OverDay',
            },
        },
    };

    return (
        <div>
            <Bar data={chartData} options={options} />
        </div>
    );
};


const CountryChart = ({ data }) => {
    // Prepare the data for the chart
    const labels = data.rows.map(row => row.dimensionValues[0].value); // Country names
    const values = data.rows.map(row => parseInt(row.metricValues[0].value, 10)); // Active users

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Active Users',
                data: values,
                backgroundColor: 'rgb(66,133,244)', // Change this color
                borderColor: 'rgb(66,133,244)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        indexAxis: 'y', // Set the index axis to 'y' for horizontal bars
        scales: {
            x: {
                beginAtZero: true, // Ensure the x-axis starts at zero
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Country Active Users',
            },
        },
    };
    

    return (
        <div>
            <Bar data={chartData} options={options} />
        </div>
    );
}

const DeviceChart = ({data}) => {

                const labels = data.rows.map(row => row.dimensionValues[0].value); // Device categories
                const values = data.rows.map(row => parseInt(row.metricValues[0].value, 10)); // Total sessions or page views

                const chartData={
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: [
                                'rgb(219,68,55)',
                                'rgb(66,133,244)',
                                'rgba(255, 206, 86, 0.6)',
                            ],
                            borderColor: 'rgba(255, 255, 255, 1)',
                            borderWidth: 1,
                        },
                    ],
                };

                const options = {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Device Category Views',
                        },
                    },
                };
            



    return (
        <div style={{ height: '300px', width: '100%'}}>
            <Pie data={chartData} options={options} />
        </div>
    );
};

const EventChart = ({ events }) => {
    // Prepare data for the chart
    const labels = events.map(event => event.eventName);
    const currentCounts = events.map(event => event.currentCount);
    const previousCounts = events.map(event => event.previousCount);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'This Week',
                data: currentCounts,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
            {
                label: 'Last Week',
                data: previousCounts,
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Website Event Counts Comparison',
            },
        },
    };

    return (
        <div>
            <Bar data={data} options={options} />
        </div>
    );
};

const UserActivityChart = ({data}) => {


    // Prepare data for the chart
    const labels30Days = data.last30Days?.map(item => {
        const date = item.date;
        return `${date.slice(6, 8)}-${date.slice(4, 6)}-${date.slice(0, 4)}`;
    }) || [];
    const activeUsers30Days = data.last30Days?.map(item => item.activeUsers) || [];

    const labels7Days = data.last7Days?.map(item => {
        const date = item.date;
        return `${date.slice(6, 8)}-${date.slice(4, 6)}-${date.slice(0, 4)}`;
    }) || [];
    const activeUsers7Days = data.last7Days?.map(item => item.activeUsers) || [];

    const labels1Day = data.last1Day?.map(item => {
        const date = item.date;
        return `${date.slice(6, 8)}-${date.slice(4, 6)}-${date.slice(0, 4)}`;
    }) || [];
    const activeUsers1Day = data.last1Day?.map(item => item.activeUsers) || [];

    const Linedata = {
        labels: labels30Days, // You can choose to display one of the time periods or combine them
        datasets: [
            {
                label: 'Active Users (Last 30 Days)',
                data: activeUsers30Days,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
            },
            {
                label: 'Active Users (Last 7 Days)',
                data: activeUsers7Days,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
            },
            {
                label: 'Active Users (Last 1 Day)',
                data: activeUsers1Day,
                borderColor: 'rgba(255, 206, 86, 1)',
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                fill: true,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Users',
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'User  Activity Over Time',
            },
        },
    };

    return (
        <div style={{height:"300px"}}>
            <Line data={Linedata} options={options} />
        </div>
    );
};

const NewUsersChart = ({ data }) => {
    // Prepare data for the chart
    const labels = data.map(item => item.channelGroup);
    const newUsers = data.map(item => item.newUsers);

    const NewUserschartData = {
        labels: labels,
        datasets: [
            {
                label: 'New Users',
                data: newUsers,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        indexAxis: 'y', // Set the index axis to 'y' for horizontal bars
        scales: {
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of New Users',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Channel Group',
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'New Users by Channel Group',
            },
        },
    };

    return (
        <div>
            <Bar data={NewUserschartData} options={options} />
        </div>
    );
};


export { AnalyticsChart, CountryChart,DeviceChart,EventChart,UserActivityChart,NewUsersChart};