import React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Card, CardContent, FormControl, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';


const Login = () => {
    const { login } = useAuth();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [sanckbaropen, setSnackbaropen] = React.useState(false);
    const [snackbarmessage, setSnackbarmessage] = React.useState('');

    const navigate = useNavigate();

    // const submitlogin=()=>{
    //     if(email==='info@selectcards.in' && password==='selectcards'){
    //         alert('Login Successful...');
    //         navigate('/dashboard');
    //     }else{
    //         alert('Invalid Credentials');
    //     }

    // }

    const handleClose = () => {

        setSnackbaropen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="success"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const submitlogin = async () => {
        try {
            const response = await fetch('https://selectcards.ai/getuser.php', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();

            // Check if the response is OK and the status is success
            if (response.ok && result.status === 'success') {
                // Check if any user in the data array matches the entered credentials
                const user = result.data.find(user => user.username === email && user.password === password);

                if (user) {
                    setSnackbarmessage("Login Successful...");
                    setSnackbaropen(true);
                    login();
                    setTimeout(() => {
                        navigate('/dashboard'); // Navigate to the dashboard
                    }, 2000);
                } else {
                    alert('Invalid Credentials');
                }
            } else {
                alert(result.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            console.error('Error during login:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    return (
        <>
            <Snackbar
                open={sanckbaropen}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <Alert onClose={handleClose} severity="success" sx={{ backgroundColor: 'white', color: 'green' }}>
                    {snackbarmessage}
                </Alert>
            </Snackbar>
            <div className="container mx-auto mt-5" style={{ backgroundColor: "#ECECEC" }}>
                <div className="row p-5">
                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <img src="https://account.asus.com/img/login_img02.png" alt="Login image" 
                    className="img-fluid" style={{width:"300px",height:"auto"}} />
                    </div> */}
                    <div className="col-12">
                        <div className="card mt-4" >
                            <div className="card-body">
                                <Typography variant="h4" className="text-center mb-3">Login</Typography>
                                <Typography>Email Address:</Typography>
                                <TextField id="standard-basic" className="form-control mb-2" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Your Email Id" variant="outlined" required />
                                <br />
                                <label>Password:</label>
                                <TextField id="standard-basic" className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter the Password" variant="outlined" required />
                                <br />
                                <div className="text-center">
                                    <Button onClick={submitlogin} className="mt-4" variant="contained">Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}

export default Login;