import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext'; // Import AuthProvider
import Submit from './Submitform';
import App from './App';
import Admin from './Admin';
import Sbibank from './Sbibank';
import Hdfcbank from './Hdfcbank';
import Idfcbank from './Idfc';
import Induslndbank from './Induslnd';
import Au from './AU';
import Icicibank from './Icici';
import Yesbank from './yesbank';
import Login from './Login';
import Navbar from './Navbar';
import Prepaidcard from './Prepaidcard';
import Axisbank from './Axisbank';
import Creditcards from './Creditcards';
import About from './About';
import Howitworks from './Howitworks';
import AllDebitcards from './AllDebitcards';
import Getintouch from './GetinTouch';
import Blogs from './Blogs';
import BlogPostForm from './Blogpost';
import SeperateBlog from './Seperateblog';
import {Airtelpayment,AxisBankDebit,IdfcDebitCard,INDUSLNDDEBITCARD} from './Debitcards';
import ProtectedRoute from './ProtectedRoute'; // Import ProtectedRoute

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <AuthProvider>
            <BrowserRouter>
                <Navbar />
                <Routes>
                    <Route path='/' element={<App />} />
                    <Route path='/submit' element={<Submit />} />
                    <Route path='/admin' element={<Login />} />
                    <Route path='/dashboard' element={<ProtectedRoute element={<Admin />} />} />
                    <Route path='/sbi' element={<Sbibank />} />
                    <Route path='/hdfc' element={<Hdfcbank />} />
                    <Route path='/idfc' element={<Idfcbank />} />
                    <Route path='/induslnd' element={<Induslndbank />} />
                    <Route path='/AU' element={<Au />} />
                    <Route path='/icici' element={<Icicibank />} />
                    <Route path='/yesbank' element={<Yesbank />} />
                    <Route path='/prepaid' element={<Prepaidcard />} />
                    <Route path='/axis' element={<Axisbank />} />
                    <Route path='/debitcards' element={<AllDebitcards/>}/>
                    <Route path='/axisdebit' element={<AxisBankDebit/>}/>
                    <Route path='/idfcdebitcard' element={<IdfcDebitCard/>}/>
                    <Route path='/induslnddebitcard' element={<INDUSLNDDEBITCARD/>}/>
                    <Route path='/creditcards' element={<Creditcards/>}/>
                    <Route path='/companyoverview' element={<About/>}/>
                    <Route path='/howitworks' element={<Howitworks/>}/>
                    <Route path='/getintouch' element={<Getintouch/>}/>
                    <Route path='/blog' element={<Blogs/>}/>
                    <Route path='/blogpost' element={<BlogPostForm/>}/>
                    <Route path="/blog/:id" element={<SeperateBlog />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    </React.StrictMode>
);